import React, { Component } from 'react'
import {connect} from 'react-redux';
import {getDkmCanceledWasteReports} from './apiCalls.js/apiEndpoints'
import WasteReportTableComponent from "../components/wasteReportComponent"

class CanceledWasteReports extends Component {
    render() {
        return (
            <WasteReportTableComponent
                reportApiCall={getDkmCanceledWasteReports}
                pageHeader="İptal Edilmiş Atık Bildirimleri"
                contentType="municipality"
            />
        )
    }
}

export default connect((store)=>({auth:store.auth}))(CanceledWasteReports)
