import React from 'react';
import Modal from 'react-modal';

const ModalWithStyles = ({
    isOpen,
    children
}) => (
    <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel="Example Modal"
    >
        {children}
    </Modal>
);

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%'
    }
};


export default ModalWithStyles;