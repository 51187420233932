import React,{Component} from 'react'
import {connect} from 'react-redux';
import {wasteReportsNotHandled} from './apiCalls.js/apiEndpoints'
import WasteReportTableComponent from "../components/wasteReportComponent"


class NotHandledWasteReports extends Component{
    render(){
        return(
            <WasteReportTableComponent
                reportApiCall={wasteReportsNotHandled}
                pageHeader="İşlem Görmemiş Atık Bildirimler"
                contentType="municipality"
            />
        )
    }  
}

export default connect((store)=>({auth:store.auth}))(NotHandledWasteReports)
