exports.getDkmCanceledWasteReports = (municipalityId, skip, limit = 50) => {
    return {
        call: `
        query{
            getDkmCanceledWasteReports(ministryId:"${municipalityId}", skip:${skip}, limit:${limit})
            {
                _id
                images
                privacy
                wasteType
                latitude
                longitude
                createdAt
                isSelf
                waste_status
                statusExplanation
                createdAt
                creator {email}
                registeredMinistry {district}
            }
        }
        `,
        endPointName: "getDkmCanceledWasteReports"
    }
}

exports.getDkmActiveWasteReports = (municipalityId, skip, limit = 50) => {
    return {
        call: `
        query{
            getDkmActiveWasteReports(ministryId:"${municipalityId}", skip:${skip}, limit:${limit})
            {
                _id
                images
                privacy
                wasteType
                latitude
                longitude
                createdAt
                isSelf
                waste_status
                createdAt
                statusExplanation
                creator {email}
                registeredMinistry {district}
            }
        }
        `,
        endPointName: "getDkmActiveWasteReports"
    }
}

exports.wasteReportsAllDkm = (municipalityId, skip, limit = 50) => {
    return {
        call: `
        query{
            wasteReportsAllDkm(ministryId:"${municipalityId}", skip:${skip}, limit:${limit})
            {
                _id
                images
                privacy
                wasteType
                latitude
                longitude
                createdAt
                isSelf
                waste_status
                createdAt
                creator {email}
                registeredMinistry {district}
            }
        }
    `,
        endPointName: "wasteReportsAllDkm"
    }
}

exports.getDkmAlarmWasteReports = (municipalityId, skip, limit = 50) => {
    return {
        call: `
        query{
            getDkmAlarmWasteReports(ministryId:"${municipalityId}", skip:${skip}, limit:${limit})
            {
                _id
                images
                privacy
                wasteType
                latitude
                longitude
                createdAt
                isSelf
                waste_status
                createdAt
                creator {email}
                registeredMinistry {district}
            }
        }
    `,
        endPointName: "getDkmAlarmWasteReports"
    }
}

exports.getDkmPastWasteReports = (municipalityId, skip, limit = 50) => {
    return {
        call: `
        query{
            getDkmPastWasteReports(ministryId:"${municipalityId}", skip:${skip}, limit:${limit})
            {
                _id
                images
                privacy
                wasteType
                latitude
                longitude
                createdAt
                isSelf
                waste_status
                statusExplanation
                createdAt
                creator {email}
                registeredMinistry {district}
            }
        }
    `,
        endPointName: "getDkmPastWasteReports"
    }
}

exports.wasteReportsNotHandled = (municipalityId, skip, limit = 50) => {
    return {
        call: `
        query{
            wasteReportsNotHandled(ministryId:"${municipalityId}", skip:${skip}, limit:${limit})
            {
                _id
                images
                privacy
                wasteType
                latitude
                longitude
                createdAt
                isSelf
                waste_status
                createdAt
                creator {email}
                registeredMinistry {district}
            }
        }
    `,
        endPointName: "wasteReportsNotHandled"
    }
}

exports.getDkmIsSelfReports = (municipalityId, skip, limit = 50) => {
    return {
        call: `
        query{
            getDkmIsSelfReports(ministryId:"${municipalityId}", skip:${skip}, limit:${limit})
            {
                _id
                images
                privacy
                wasteType
                latitude
                longitude
                createdAt
                isSelf
                waste_status
                createdAt
                creator {email}
                registeredMinistry {district}
            }
        }
    `,
        endPointName: "getDkmIsSelfReports"

    }
}
