import React, {Component} from 'react'
import Select from 'react-select'
import {connect} from 'react-redux';
import AuthContext from '../context/auth-context'
import { call } from '../services/api'
import{setMunicipality} from '../store/actions'

class Sidebar extends Component {

    constructor(props){
        super(props)
        this.state = {
            municipalityOptions: [],
            registeredMinistry: {value: "Tümü", label: "Tümü"},
            isFirst: true
        }
    }

    async componentWillMount() {
        const ministryRequest = {
            query: `query {
                getMinistry{
                    _id
                    district
                }
            }`
        }

        call(ministryRequest).then(async resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }
            console.log(resData)
            let municipalityOptions = [{value: "Tümü", label: "Tümü"}]
            for(let i = 0; i < resData.data.getMinistry.length; i++){
                let object = {value: resData.data.getMinistry[i]._id, label: resData.data.getMinistry[i].district}
                municipalityOptions.push(object)
            }

            await this.setState({ ministries: resData.data.getMinistry, dataCame: true, municipalityOptions: municipalityOptions })

        }).catch(err => { console.log(err) })

    }

    render(){
    return(
    <AuthContext.Consumer>
       {(context)=>{
            if(this.props.auth.isAuthenticated)
            {
                return (<aside class="left-sidebar">
                <div class="scroll-sidebar">
                    <nav class="sidebar-nav">
                        <ul id="sidebarnav">
                            <li> <a class="waves-effect waves-dark" href="/index" ><i class="icon-speedometer"></i><span class="hide-menu">Anasayfa </span></a>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="icon-login"></i><span class="hide-menu">Atık Bildirimleri</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    {this.state.dataCame &&  <h4>Belediye Seç</h4>}
                                    {this.state.dataCame && <li>
                                    <Select value={{value: this.props.auth.municipality.value, label: this.props.auth.municipality.label}} 
                                    onChange={async (selectedOption) => {await this.props.setMunicipality(selectedOption);
                                    }} isMulti={false} options={this.state.municipalityOptions} placeholder="Tümü"
                                    /></li>}
                                    <hr></hr>
                                    <li><a href="activeWasteReports">Aktif Bildirimler</a></li>
                                    <li><a href="pastWasteReports">Geçmiş Bildirimler</a></li>
                                    <li><a href="canceledWasteReports">İptal Edilen Bildirimler</a></li>
                                    <li><a href="allWasteReports">Belediye Tüm Bildirimler</a></li>
                                    <li><a href="isSelfWasteReports">Kendim Atacağım Bildirimleri</a></li>
                                    <li><a href="notHandledWasteReports">Uzun Süre İşlem Görmemiş Bildirimler</a></li>
                                    <li><a href="instantMuchReports">Alarm Bildirimleri</a></li>
                                    <li><a href="advancedReportQuery">Gelişmiş Bildirim Filtreleme</a></li>
                                </ul>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-bar-chart-alt"></i><span class="hide-menu">Kullanıcı İşlemleri</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="userBanning">Kullanıcı Görüntüle</a></li>
                                    {/* <li><a href="advancedUserQuery">Tarihe Göre Kullanıcı</a></li> */}
                                </ul>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-bar-chart-alt"></i><span class="hide-menu">Excel Veri İndirme</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="downloadExcel">Excel İndir</a></li>
                                    {/* <li><a href="advancedUserQuery">Tarihe Göre Kullanıcı</a></li> */}
                                </ul>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-bar-chart-alt"></i><span class="hide-menu">Raporlar</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="wasteReporting">Atık Raporları</a></li>
                                    <li><a href="userReporting">Kullanıcı Raporları</a></li>
                                    <li><a href="ministryReporting">Belediye Raporları</a></li>
                                    <li><a href="ageGenderReporting">Yaşa/Cinsiyete Göre Kullanıcı</a></li>
                                    <li><a href="generalMinistryReporting">Genel Bölge/Belediye Raporları</a></li>
                                    <li><a href="wasteReportPlace">Bildirim Lokasyonları</a></li>
        

                                </ul>
                            </li>
        
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="icon-direction"></i><span class="hide-menu">Konteynerler</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="/konteyner">Tüm Konteynerler</a></li>
                                    <li><a href="/addKonteyner">Konteyner Ekle</a></li>
                                    <li><a href="/addKonteynerExcel">Excel Konteyner Ekle</a></li>
                                    
                                </ul>
                            </li>
        
                        <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="icon-pin"></i><span class="hide-menu">Etkinlikler</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="/createEvent">Etkinlik Talebi</a></li>
                                    <li><a href="/activeEvents">Aktif Etkinlikler</a></li>
                                    <li><a href="/pastEvents">Geçmiş Etkinlikler</a></li>
                                    <li><a href="/allEvents">Tüm Etkinlikler</a></li>                            
                                </ul>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="icon-pin"></i><span class="hide-menu">Duyurular ve İletişim</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="/announcements">Duyuru Yönet</a></li>
                                    <li><a href="/addAnnouncement">Duyuru Ekle</a></li>
                                    <li><a href="/communicationInfos">İletişim Bilgilerini Yönet</a></li>
                                    <li><a href="/addCommunicationInfo">İletişim Bilgisi Ekle</a></li>                            
                                </ul>
                            </li>
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="icon-pin"></i><span class="hide-menu">Toplu Mail</span></a>
                                <ul aria-expanded="false" class="collapse">
                                    <li><a href="/mailToAll">Toplu Mail At</a></li>                        
                                </ul>
                            </li>
          
                            <li class="nav-small-cap">--- HESAP İŞLEMLERİ</li>
        
                            <li> <a class="has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false"><i class="ti-email"></i><span class="hide-menu">Hesap İşlemleri</span></a>
                                <ul aria-expanded="false" class="collapse">
                                <li><a href="addMinistry">Belediye Oluştur</a></li>
                                <li><a href="createMinistryAccount">Belediye Hesabı Oluştur</a></li>

                                    <li><a href="allAccounts">Tüm Hesaplar</a></li>
                                    <li><a href="createDKMAccount">DKM Hesabı Oluştur</a></li>
                                </ul>
                            </li>
                            <li> <a href="logout" class="waves-effect waves-dark" href="/logout" aria-expanded="false"><i class="icon-speedometer"></i><span class="hide-menu">Çıkış Yap </span></a>
                            </li>
                        </ul>
                    </nav>
                 </div>
                </aside>)
            }
            else{
                return null
            }
       }}
    </AuthContext.Consumer>
            )    }
}

export default connect((store)=>({auth:store.auth}),{setMunicipality})(Sidebar)