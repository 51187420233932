import React,{Component} from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import {dateConvertion} from '../helpers/dateHelpers'
import { call } from '../../services/api';


class GeneralMinistryReport extends Component{

    

    constructor(props) {
        super(props);
        this.state = {
            ministries: [],
            dataCame: false,
            showStats: false,
            selectedMinistryId:"Seç",
            stats: {}
        }
        
    
      }

    async componentWillMount(){
        const ministryRequest = {
            query: `query{
                getWasteReportByMinistry{
                userActivityReportByRegion{
                  _id
                  count
                }
                ministryActivityByWaste{
                    _id
                  count
                }
                ministryActivityByLastMonth{
                  _id
                  count
                }
              }
            }`
        }

        call(ministryRequest).then(resData=>{
            if(resData.errors) 
            {
                console.log("unauthenticated")
            }
            console.log(resData)
           
            
            this.setState({stats: resData.data.getWasteReportByMinistry,  dataCame:true,showStats:true})
            
        }).catch(err=>{console.log(err)})

    }

 

    render(){
        if(!this.state.dataCame){
            return(
                <div class="page-wrapper">
                <div class="container-fluid">
                <h1>Bekleyin...</h1>
                </div>
                </div>
            )
        }else if(!this.state.showStats){
            return (

                <div class="page-wrapper">
                    <div class="container-fluid">
                    <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Belediye Filtrele</h4>
                        <select class="form-control-lg " onChange={async (e) => {await this.setState({ selectedMinistryId: e.target.value })}}>
                        <option value="Seç">Seç</option>
                            {this.state.ministries.map(ministry => {
                                return(
                                    <option value={ministry._id}>{ministry.district}</option>
                                )
                            })}
                          
                        </select>
                        <br/>
                        <button class="btn btn-primary" type="button" style={{marginTop:"4%"}} onClick={async () => {this.getMinistryStats()}}>Filtreyi Uygula</button>
                    </div>
                    </div>
                    </div>
                </div>    
            )    
        }
        return (

        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Genel Bölge/Belediye Raporları</h4>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row p-t-10 p-b-10">                         
                                <div class="col p-r-0">
                                    <h5 class="font-light">Atık Bildirimi Cevaplama Sıralaması</h5>
                                    <div class="table-responsive">
                                    <table class="table table-hover">
                                        
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th>Belediye</th>
                                                <th>Atık Bildirimi Sayısı</th>
                                            </tr>
                                        </thead>
                                        {this.state.stats.ministryActivityByWaste.map((report,index )=> 
                
                                            <tbody>
                                            <tr>
                                                <td class="text-center">{index+1}</td>
                                                <td class="txt-oflo">{report._id}</td>
                                                <td class="txt-oflo">{report.count}</td>

                                                
                                            </tr>
                                            </tbody> 
                                        )}
                                        </table>
                                     </div>    
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div class="col-lg-4 col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row p-t-10 p-b-10">                         
                                <div class="col p-r-0">
                                    <h5 class="font-light">Kullanıcıların En Aktif Olduğu Bölgeler (Kendim Atacağım)</h5>
                                    <div class="table-responsive">
                                    <table class="table table-hover">
                                        
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th>Bölge</th>
                                                <th>Atık Bildirimi Sayısı</th>
                                            </tr>
                                        </thead>
                                        {this.state.stats.userActivityReportByRegion.map((report,index )=> 
                
                                            <tbody>
                                            <tr>
                                                <td class="text-center">{index+1}</td>
                                                <td class="txt-oflo">{report._id}</td>
                                                <td class="txt-oflo">{report.count}</td>

                                                
                                            </tr>
                                            </tbody> 
                                        )}
                                        </table>
                                     </div>    
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="col-lg-4 col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="row p-t-10 p-b-10">                         
                                <div class="col p-r-0">
                                    <h5 class="font-light">Atık Bildirimi Cevaplama Sıralaması (Son 1 Ay)</h5>
                                    <div class="table-responsive">
                                    <table class="table table-hover">
                                        
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th>Bölge</th>
                                                <th>Atık Bildirimi Sayısı</th>
                                            </tr>
                                        </thead>
                                        {this.state.stats.ministryActivityByLastMonth.map((report,index )=> 
                
                                            <tbody>
                                            <tr>
                                                <td class="text-center">{index+1}</td>
                                                <td class="txt-oflo">{report._id}</td>
                                                <td class="txt-oflo">{report.count}</td>

                                                
                                            </tr>
                                            </tbody> 
                                        )}
                                        </table>
                                     </div>    
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div> 
            </div>
        </div>  
         
        )
    }
}

export default GeneralMinistryReport