import React from 'react';

const Button = ({
    onClick,
    disabled,
    extraStyle,
    children
}) => (
    <button
        class="btn btn-primary"
        disabled={disabled}
        onClick={onClick}
        style={extraStyle}
    >
        {children}
    </button>
)

export default Button;