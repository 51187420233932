exports.cities = [
    {"code":"1", "name":"ADANA" },
    {"code":"2", "name":"ADIYAMAN" },
    {"code":"3", "name":"AFYONKARAHİSAR" },
    {"code":"4", "name":"AĞRI" },
    {"code":"5", "name":"AKSARAY" },
    {"code":"6", "name":"AMASYA" },
    {"code":"7", "name":"ANKARA" },
    {"code":"8", "name":"ANTALYA" },
    {"code":"9", "name":"ARDAHAN" },
    {"code":"10", "name":"ARTVİN" },
    {"code":"11", "name":"AYDIN" },
    {"code":"12", "name":"BALIKESİR" },
    {"code":"13", "name":"BARTIN" },
    {"code":"14", "name":"BATMAN" },
    {"code":"15", "name":"BAYBURT" },
    {"code":"16", "name":"BİLECİK" },
    {"code":"17", "name":"BİNGÖL" },
    {"code":"18", "name":"BİTLİS" },
    {"code":"19", "name":"BOLU" },
    {"code":"20", "name":"BURDUR" },
    {"code":"21", "name":"BURSA" },
    {"code":"22", "name":"ÇANAKKALE" },
    {"code":"23", "name":"ÇANKIRI" },
    {"code":"24", "name":"ÇORUM" },
    {"code":"25", "name":"DENİZLİ" },
    {"code":"26", "name":"DİYARBAKIR" },
    {"code":"27", "name":"DÜZCE" },
    {"code":"28", "name":"EDİRNE" },
    {"code":"29", "name":"ELAZIĞ" },
    {"code":"30", "name":"ERZİNCAN" },
    {"code":"31", "name":"ERZURUM" },
    {"code":"32", "name":"ESKİŞEHİR" },
    {"code":"33", "name":"GAZİANTEP" },
    {"code":"34", "name":"GİRESUN" },
    {"code":"35", "name":"GÜMÜŞHANE" },
    {"code":"36", "name":"HAKKARİ" },
    {"code":"37", "name":"HATAY" },
    {"code":"38", "name":"IĞDIR" },
    {"code":"39", "name":"ISPARTA" },
    {"code":"40", "name":"İSTANBUL" },
    {"code":"41", "name":"İZMİR" },
    {"code":"42", "name":"KAHRAMANMARAŞ" },
    {"code":"43", "name":"KARABÜK" },
    {"code":"44", "name":"KARAMAN" },
    {"code":"45", "name":"KARS" },
    {"code":"46", "name":"KASTAMONU" },
    {"code":"47", "name":"KAYSERİ" },
    {"code":"48", "name":"KİLİS" },
    {"code":"49", "name":"KIRIKKALE" },
    {"code":"50", "name":"KIRKLARELİ" },
    {"code":"51", "name":"KIRŞEHİR" },
    {"code":"52", "name":"KOCAELİ" },
    {"code":"53", "name":"KONYA" },
    {"code":"54", "name":"KÜTAHYA" },
    {"code":"55", "name":"MALATYA" },
    {"code":"56", "name":"MANİSA" },
    {"code":"57", "name":"MARDİN" },
    {"code":"58", "name":"MERSİN" },
    {"code":"59", "name":"MUĞLA" },
    {"code":"60", "name":"MUŞ" },
    {"code":"61", "name":"NEVŞEHİR" },
    {"code":"62", "name":"NİĞDE" },
    {"code":"63", "name":"ORDU" },
    {"code":"64", "name":"OSMANİYE" },
    {"code":"65", "name":"RİZE" },
    {"code":"66", "name":"SAKARYA" },
    {"code":"67", "name":"SAMSUN" },
    {"code":"68", "name":"ŞANLIURFA" },
    {"code":"69", "name":"SİİRT" },
    {"code":"70", "name":"SİNOP" },
    {"code":"71", "name":"ŞIRNAK" },
    {"code":"72", "name":"SİVAS" },
    {"code":"73", "name":"TEKİRDAĞ" },
    {"code":"74", "name":"TOKAT" },
    {"code":"75", "name":"TRABZON" },
    {"code":"76", "name":"TUNCELİ" },
    {"code":"77", "name":"UŞAK" },
    {"code":"78", "name":"VAN" },
    {"code":"79", "name":"YALOVA" },
    {"code":"80", "name":"YOZGAT" },
    {"code":"81", "name":"ZONGULDAK" },
]