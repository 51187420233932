import React,{Component} from 'react'
import Select from 'react-select';
import {dateConvertion} from '../helpers/dateHelpers'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {call} from '../../services/api';
import reportIndicator from '../../images/reportIndicator.png'
import reportIndicatorself from '../../images/reportIndicatorblue.png'
import {showKonteynerStatusName, showKonteynerTypeName, ShowKonteynerNameAccordingToType} from '../helpers/markerHelpers'
import { limitOptions } from '../components/wasteReportComponent';
class WasteReportPlace extends Component{

    state = {
        wasteReports: [],
        konteyners: [],
        dataCame: false,
        typeCounts: [{count: 0, isim:"Pet Şişe"}],
        aylik: false,
        yillik: true,
        yillar: [],
        aylar: [],
        aylarText: ["", "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        yearValue: 2018,
        monthValue: 1,
        startShowing: false,
        filteredWasteReports: [],
        selfFilteredWasteReports:[], 
        selfReports:[],
        showSelfReports: false,
        showBelediyeReports: true,
        reportFiltering: "Belediye",
        //for map
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        latitude: null,
        longitude: null,
        report: {createdAt:"asd"},
        date: null,
        returnBack: false,
        explanationValue: "",
        cancelModalBool: false,
        finishModalBool: false,
        wasteStatus: "",
        errorMessage:"",
        ministries:[],
        showWasteReports: false,
        selectedMinistryId: "Tümü",

        repId: "",
        repImage: "",
        repInside: "",
        repUser: "",
        repDate: "",
        wasteStatus: "",
        skip: 0,
        limit: 50,
        
    }

    async componentWillMount(){


        const ministryRequest = {
            query: `query {
                getMinistry{
                    _id
                    district
                }
            }`
        }

        call(ministryRequest).then(resData=>{
            if(resData.errors) 
            {
                console.log("unauthenticated")
            }
            console.log(resData)
            
            this.setState({ministries: resData.data.getMinistry,  dataCame:true})
            
        }).catch(err=>{console.log(err)})

        var yil = []
        var ay = []
        for(var i = 0; i < 25; i++){
            yil.push(2018 + i)
        }
        for(var i = 1; i < 13; i++){
            ay.push(i)
        }
        
        await this.setState({yillar: yil, aylar: ay})     
    }

    setFilter = async (isMunicipalityChange) => {
        if (isMunicipalityChange) {
            await this.setState({ skip: 0, wasteReports:[], selfRepors:[] })
        }
        const { skip, limit } = this.state;

        await this.setState({dataCame:false})
        const requestBody = {
            query: `
                query{
                    wasteReportsSelfAllNotLimited(ministryId:"${this.state.selectedMinistryId}", skip:${skip}, limit:${limit})
                    {
                        _id
                        images
                        privacy
                        latitude
                        longitude
                        createdAt
                        isSelf
                        waste_status
                        createdAt
                        wasteType
                        creator {email}
                    }
                }
            `
        }
        

        
        call(requestBody).then(async resData => {
            if(resData.errors) {
                await this.setState({ dataCame:true })
            } else {
                let wasteRepr = this.state.wasteReports
                let selfRep = this.state.selfReports
                let newSkip = skip + resData.data.wasteReportsSelfAllNotLimited.length;
                resData.data.wasteReportsSelfAllNotLimited.map(rep => {
                    if(rep.isSelf === false){
                        wasteRepr.push(rep)
                    }else if(rep.isSelf === true){
                        selfRep.push(rep)
                    }
                })
            
                await this.setState({
                    wasteReports: wasteRepr,
                    showWasteReports: true,
                    filteredWasteReports:wasteRepr,
                    selfReports: selfRep,
                    dataCame: true,
                    skip: newSkip,
                })
            }
            }).catch(err=>{console.log(err); this.setState({dataCame:true})
        })
    }


    showStatus(stat){
        if(stat === "bekliyor"){
            return "beklemede"
        }
    }

    async setFilteredWasteReports(){
        let forFilteredReport = []
        let forFilteredReportSelf = []
        await this.state.wasteReports.map(rep => {
            if(this.state.yillik && new Date(Number(rep.createdAt)).getFullYear() === Number(this.state.yearValue)){
                forFilteredReport.push(rep)
            }else if(this.state.aylik && new Date(Number(rep.createdAt)).getFullYear() === Number(this.state.yearValue) && new Date(Number(rep.createdAt)).getMonth() + 1 === Number(this.state.monthValue)){
                forFilteredReport.push(rep)
            }
        })
        await this.state.selfReports.map(rep => {
            if(this.state.yillik && new Date(Number(rep.createdAt)).getFullYear() === Number(this.state.yearValue)){
                forFilteredReportSelf.push(rep)
            }else if(this.state.aylik && new Date(Number(rep.createdAt)).getFullYear() === Number(this.state.yearValue) && new Date(Number(rep.createdAt)).getMonth() + 1 === Number(this.state.monthValue)){
                forFilteredReportSelf.push(rep)
            }
        })
        
      
        await this.setState({filteredWasteReports: forFilteredReport})
        await this.setState({selfFilteredWasteReports: forFilteredReportSelf})
        
        await this.setState({startShowing: true})
      
    }


     setReportFiltering = async ( val ) => {
        if(val === "Belediye"){
            await this.setState({showBelediyeReports: true, showSelfReports: false})
        }else if(val === "Kendim"){
            await this.setState({showBelediyeReports: false, showSelfReports: true})
        }else if(val === "Hepsi"){
            await this.setState({showBelediyeReports: true, showSelfReports: true})
        }
    }


 

onMarkerClick = async (props, marker, e) =>{
   
    let repImg = ""
    if(marker.name.images.length !== 0){
        repImg = "https://api.kollektapp.org/" + marker.name.images[0].slice(10)
    }

    await this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      repId: marker.name._id,
      repImage: repImg,
      repInside: ShowKonteynerNameAccordingToType(marker.name.wasteType),
      repUser: marker.name.creator.email,
      repDate: dateConvertion(new Date(Number(marker.name.createdAt))) ,
      wasteStatus: marker.name.waste_status
    });
    console.log(this.state.repImage)
}
 
   

    render(){
        if(!this.state.dataCame){
            return(
                <div class="page-wrapper">
                <div class="container-fluid">
                <h1>Bekleyin...</h1>
                </div>
                </div>
            )
        }
        if(!this.state.showWasteReports){
            return (
                <div class="page-wrapper">
                    <div class="container-fluid">
                    <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Belediye Filtrele</h4>
                        <select class="form-control-lg " onChange={async (e) => {await this.setState({ selectedMinistryId: e.target.value })}}>
                        <option value="Tümü">Tümü</option>
                            {this.state.ministries.map(ministry => {
                                return(
                                    <option value={ministry._id}>{ministry.district}</option>
                                )
                            })}
                        </select>
                        <br/>
                        <button class="btn btn-primary" type="button" style={{marginTop:"4%"}} onClick={async () => {this.setFilter()}}>Filtreyi Uygula</button>
                    </div>
                    </div>
                    </div>
                </div>    
            )    
        }
        return (

        <div class="page-wrapper">
            <div class="container-fluid">
            <div className ="column">
                    
            <div class="col-md-5 align-self-center">
                    <div style={{ display: 'flex' }}>
                        <Select 
                            styles={{ input: styles => ({ ...styles, width: 50 }) }}
                            value={{value: this.state.limit, label: this.state.limit}} 
                            onChange={async (selectedOption) => {await this.setState({ limit: selectedOption.value })}}
                            isMulti={false} options={limitOptions} placeholder="50"
                        />
                        <button class="btn btn-primary" onClick={() => this.setFilter(false)}>{this.state.limit} adet daha yükle</button>
                    </div>
                    <h4 class="text-themecolor">Belediye Filtrele</h4>
                        <select class="form-control-lg " onChange={async (e) => {await this.setState({ selectedMinistryId: e.target.value })}}>
                        <option value="Tümü">Tümü</option>
                            {this.state.ministries.map(ministry => {
                                return(
                                    <option value={ministry._id}>{ministry.district}</option>
                                )
                            })}
                        </select>
                        <br/>
                        <button class="btn btn-primary" type="button" style={{marginTop:"4%"}} onClick={async () => {this.setFilter(true)}}>Filtreyi Uygula</button>
                    </div>

                    <div className="card">
                        <div className="card-body">
                        
                            <div class="row col-md-12">
                                <div className="col-md-6 btn-group">
                                <div className="col-md-12" style={{ marginTop:"1%"}}>
                                    <button class="btn btn-primary col-12" style={this.state.yillik ? {backgroundColor:"#fd6a3f"}: {}} type="button" onClick={async () => await this.setState({yillik: true, aylik: false})}>Yıllık Rapor</button>
                                    <button class="btn btn-primary col-12" style={this.state.aylik ? {backgroundColor:"#fd6a3f"}: {}} type="button" onClick={async () => await this.setState({yillik: false, aylik: true})}>Aylık Rapor</button>
                                </div>


                                </div>
    
                                
    
                            <div className="col-md-6">
                            <div class="btn-group" style={{marginBottom:"1%"}}>
                            <div className="column">
                            <div className="row-12">
                                <select class="form-control-lg " onChange={async (e) => {await this.setState({ yearValue: e.target.value })}}>
                                    {this.state.yillar.map(yil => {
                                        return(
                                            <option value={yil}>{yil}</option>
                                        )
                                    })}
                                    </select>
                                    {this.state.aylik && 
                                    <select class="form-control-lg " onChange={async (e) => {await this.setState({ monthValue: e.target.value }); console.log(this.state.monthValue)}}>
                                        {this.state.aylar.map(ay => {
                                            return(
                                                <option value={ay}>{this.state.aylarText[ay]}</option>
                                            )
                                        })}
                                    </select>
                                    }
                                    <select class="form-control-lg " onChange={async (e) => {this.setReportFiltering(e.target.value)}}>                                    
                                        <option value="Belediye">Belediye Bildirimleri</option>
                                        <option value="Kendim">Kendim Bildirimleri</option>
                                        <option value="Hepsi">Hepsi</option>
                                    </select>
                                </div>
                                {this.state.dataCame &&
                                <button class="btn btn-primary" type="button" style={{marginTop:"4%"}} onClick={async () => {this.setFilteredWasteReports()}}>Raporu Görüntüle</button>
                                }
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>   
    
                                </div>
                                </div>
            <div class="row">
                <div class="col-md-12">
                <div style={{ height: '80vh', width: '90%' }}>
                    {this.state.startShowing && this.state.dataCame && this.state.selfFilteredWasteReports.length > 0 && this.state.filteredWasteReports.length > 0 && 
                  <Map
                     
                        google={this.props.google} 
                        zoom={17}
                        initialCenter={{
                            lat: 36.59778,
                            lng: 30.56056
                        }}
                    >
                        {this.state.showBelediyeReports &&
                        this.state.filteredWasteReports.map(rep => {
                          
                            return(
                            <Marker 
                            onClick={this.onMarkerClick}  
                            position={{lat: rep.latitude, lng: rep.longitude}}
                            name={rep}
                            icon={{
                            url: reportIndicator,
                            scaledSize: {width:16, height:16}    
                            }}
                         
                            />
                           
                            )
                        }
                        )
                        }
                        {this.state.showSelfReports && 
                        this.state.selfFilteredWasteReports.map(rep => {
                            return(
                                <Marker         
                                onClick={this.onMarkerClick}                   
                                position={{lat: rep.latitude, lng: rep.longitude}}
                                name={rep}
                                icon={{
                                url: reportIndicatorself,
                                scaledSize: {width:16, height:16}    
                                }}
                             
                                />
                                )
                        })
                        }
                           <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}>
                            <div>
                            <h5><b>id</b> {this.state.repId}</h5>
                            <p><b>Neler Atılmış:</b> {this.state.repInside}</p>
                            <p><b>Durum:</b> {this.state.wasteStatus}</p>
                            <p><b>Tarih:</b> {this.state.repDate}</p>
                            <p><b>Kullanıcı:</b> {this.state.repUser}</p>
                            <p><b>Resim:</b> <img style={{width:100, height:100}} src={this.state.repImage}></img></p>
                            </div>
                        </InfoWindow>
                          
                   </Map> }            
                </div>
                </div>
                </div>

                </div>
            </div>
           
                       
         
        )
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyAdA0AjaFE0dcPMMqv9dRT06Pb1_A9xSS4")
  })(WasteReportPlace)
