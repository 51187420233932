import React, { Component } from 'react'
import Select from 'react-select';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import TableComponent from './tableScreen/tableComponent'
import PageHeader from './pageHeader'
import PageWrapper from './pageWrapper'
import wasteReportApiCall from './tableScreen/componentHelpers/wasteReportApiCall'
import DeleteModalBody from '../components/modalBodies/deleteModalBody'
import { createDataTable } from './tableScreen/componentHelpers/createDataTable'
import { deleteReport, isDeleted } from './tableScreen/componentHelpers/generalHelpers'
import { municipalityReportsHeaders, municipalityReportsContent, isSelfReportHeaders, isSelfReportsContent } from './wasteReportComponentHelpers/wReportsTableComponentData'

export const limitOptions = [
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 400, label: '400' },
    { value: 800, label: '800' },
    { value: 1000, label: '1000' },
]
class WasteReportTableComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            wasteReports: [],
            konteyners: [],
            dataCame: false,
            selectedMinistryId: "Tümü",
            ministries: null,
            showWasteReports: false,
            deletedReportId: "",
            deletedReportIds: [],
            beforeMunicipality: "Tümü",
            skip: 0,
            limit: 50,
            disableLoadMore: false
        }
        this.handleModalClose = this.handleModalClose.bind(this);
        this.delete = this.delete.bind(this);
    }

    async componentWillMount() {
        this.setFilter()
    }

    setFilter = async () => {
        const { skip, limit } = this.state;
        await this.setState({ showWasteReports: false, dataCame: false })

        let endPointInfoObj = this.props.reportApiCall(this.props.auth.municipality.value, skip, limit)
        const resultObj = await wasteReportApiCall(endPointInfoObj.call, endPointInfoObj.endPointName)
        const newSkip = skip + resultObj.wasteReports.length;
        await this.setState({
            dataCame: resultObj.dataCame,
            wasteReports: resultObj.wasteReports,
            showWasteReports: resultObj.showWasteReports,
            beforeMunicipality: this.props.auth.municipality.value,
            skip: newSkip
        })
        createDataTable()
    }

    loadMore = async () => {
        const { skip, limit, wasteReports } = this.state;
        await this.setState({ showWasteReports: false, dataCame: false })

        let endPointInfoObj = this.props.reportApiCall(this.props.auth.municipality.value, skip, limit)
        const resultObj = await wasteReportApiCall(endPointInfoObj.call, endPointInfoObj.endPointName)
        const newSkip = skip + resultObj.wasteReports.length;
        const newWasteReports = [...wasteReports, ...resultObj.wasteReports];

        await this.setState({
            disableLoadMore: resultObj.wasteReports.length === 0 && resultObj.showWasteReports,
            dataCame: resultObj.dataCame,
            wasteReports: newWasteReports,
            showWasteReports: resultObj.showWasteReports,
            beforeMunicipality: this.props.auth.municipality.value,
            skip: newSkip
        })
        window.$('#example23').DataTable().draw();
    }

    delete() {
        deleteReport(this.state.deletedReportId, this.state.deletedReportIds)
            .then(async result => {
                await this.setState({
                    deleteSuccessMessage: result.deleteSuccessMessage,
                    deletedReportIds: result.deletedReportIds,
                    errorMessage: result.errorMessage
                })
            }
            )
            .catch(async result => {
                await this.setState({
                    deleteSuccessMessage: result.deleteSuccessMessage,
                    errorMessage: result.errorMessage
                })
            })
    }

    async handleModalClose() {
        await this.setState({ deleteSuccessMessage: "", errorMessage: "", deletedKonteynerId: "", cancelModalBool: false });
    }

    setTableContent(rep) {
        if (this.props.contentType === "municipality") {
            return municipalityReportsContent(rep)
        } else if (this.props.contentType === "isSelf") {
            return isSelfReportsContent(rep)
        }
    }
    setTableHeader() {
        if (this.props.contentType === "municipality") {
            return municipalityReportsHeaders()
        } else if (this.props.contentType === "isSelf") {
            return isSelfReportHeaders()
        }
    }

    render() {
        if (!this.state.dataCame) {
            return (
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Bekleyin...</h1>
                    </div>
                </div>
            )
        }
        if (this.state.beforeMunicipality !== this.props.auth.municipality.value) {
            this.setFilter()
        }
        return (
            <PageWrapper>
                <Modal
                    isOpen={this.state.cancelModalBool}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <DeleteModalBody 
                        errorMessage={this.state.errorMessage}
                        successMessage={this.state.deleteSuccessMessage}
                        onDeleteClick={this.delete}
                        onCloseClick={this.handleModalClose}
                    />
                </Modal>
                <PageHeader pageHeader={this.props.pageHeader} />
                <div style={{ display: 'flex' }}>
                    <Select 
                        styles={{ input: styles => ({ ...styles, width: 50 }) }}
                        value={{value: this.state.limit, label: this.state.limit}} 
                        onChange={async (selectedOption) => {await this.setState({ limit: selectedOption.value })}}
                        isMulti={false} options={limitOptions} placeholder="50"
                    />
                    <button class="btn btn-primary" disabled={this.state.disableLoadMore} onClick={this.loadMore}>{this.state.limit} adet daha yükle</button>
                </div>
                <TableComponent
                    tableHeadersArray={this.setTableHeader()}

                    tableContentArray={
                        this.state.wasteReports.map(rep => {
                            let contentArray = this.setTableContent(rep)
                            let deleteFunctionality = <><span class="text-muted"><i class="fa fa-clock-o"></i> <button class="btn btn-primary" disabled={isDeleted(this.state.deletedReportIds, rep._id, true)} onClick={async () => { await this.setState({ deletedReportId: rep._id, cancelModalBool: true }) }}>{isDeleted(this.state.deletedReportIds, rep._id, false)}</button></span></>
                            contentArray.push(deleteFunctionality)
                            return contentArray
                        })
                    }
                    images={
                        this.state.wasteReports.map(rep => {
                            if (rep.images instanceof Array) {
                                if (rep.images.length !== 0) {
                                    if (rep.images[0] !== undefined)
                                        return "https://api.kollektapp.org/" + rep.images[0].slice(10)
                                }
                            }
                        })
                    }
                    pageHeader={this.props.pageHeader}
                />
            </PageWrapper >
        )
    }

}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%'
    }
};

export default connect((store) => ({ auth: store.auth }))(WasteReportTableComponent)
