import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { dateConvertion, dateConvertionForSort } from '../helpers/dateHelpers'
import Modal from 'react-modal';
import Select from 'react-select';
import { call } from '../../services/api'
import { limitOptions } from '../components/wasteReportComponent';
import { createDataTable } from '../components/tableScreen/componentHelpers/createDataTable';

class IsSelfWasteReports extends Component {

    state = {
        users: [],
        konteyners: [],
        dataCame: false,
        selectedMinistryId: "Tümü",
        ministries: null,
        showWasteReports: false,
        banModalBool: false,
        banValue: "",
        selectedUser: "",
        errorMessage: "",
        limit: 50,
        skip: 0,
        disableLoadMore: false
    }

    async componentWillMount() {
        this.loadMore()
    }

    banUser() {
        if (this.state.banValue === "" || this.state.selectedUser === "") {
            return
        }

        const banRequest = {
            query: `
            mutation{
                banUser(userId: "${this.state.selectedUser._id}", banType: "${this.state.banValue}")
                {_id}
            }
        `}

        call(banRequest)
            .then(async resData => {
                if (resData.errors) {
                    await this.setState({ errorMessage: "işlem başarısız." })
                    console.log("here")
                } else {
                    this.setState({ errorMessage: "İşlem başarılı." })
                }
            })
            .catch(async err => {
                await this.setState({ errorMessage: "İşlem başarısız." })
            })
    }


    showStatus(stat) {
        if (stat === "bekliyor") {
            return "beklemede"
        }
    }

    loadMore = async () => {
        const { limit, skip, users } = this.state;
        const requestBody = {
            query: `
                query{
                    getAllUsers(limit:${limit}, skip:${skip})
                    {
                        _id
                        name
                        email
                        createdAt
                        userType
                        point
                        registeredDevice
                    }
                }
            `
        }
        await this.setState({ showWasteReports: false, dataCame: false })

        call(requestBody).then(async resData => {
            let newSkip = skip;
            let newUsers = users;
            let disableLoadMore = false;

            if (resData.errors) {
                console.log("unauthenticated")
            } else if (resData && resData.data && resData.data.getAllUsers) {
                newSkip = skip + resData.data.getAllUsers.length;
                if (resData.data.getAllUsers.length === 0) {
                    disableLoadMore = true
                }
                newUsers = [...users, ...resData.data.getAllUsers];
            }
            await this.setState({ users: newUsers, showWasteReports: true, dataCame: true, skip: newSkip, disableLoadMore })
            createDataTable();

        }).catch(err => { console.log(err); this.setState({ dataCame: true }); createDataTable(); })
    }

    render() {
        if (!this.state.dataCame) {
            return (
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Bekleyin...</h1>
                    </div>
                </div>
            )
        }


        return (

            <div class="page-wrapper">
                <div class="container-fluid">
                    <Modal
                        isOpen={this.state.banModalBool}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <h2>Ban Türünü Seçin</h2>

                        <form>
                            <select
                                onChange={async (e) => { await this.setState({ banValue: e.target.value }) }}
                                style={{ width: "100%", height: "100%" }} name="cancelReasons" size="5" multiple
                            >
                                {/*<option value="Kısa_Ban">Kısa Süreli</option>*/}
                                <option value="BANNED">Banla</option>
                                <option value="VERIFIED">Banı Kaldır</option>
                            </select>
                        </form>
                        {this.state.errorMessage !== "" && <h4 style={this.state.errorMessage === "İşlem başarılı." ? { color: "green" } : { color: "red" }}>
                            {this.state.errorMessage}
                            <button class="btn btn-primary" type="button" style={{ marginLeft: "0.3%", marginTop: "1%" }} onClick={async () => { await this.setState({ banModalBool: false, banValue: "", errorMessage: "", selectedUser: "" }) }}>Kapat</button>
                        </h4>}
                        <button class="btn btn-primary" type="button" style={{ marginRight: "0.3%", marginTop: "1%" }} onClick={async () => { this.banUser() }}>Onayla</button>
                        <button class="btn btn-primary" type="button" style={{ marginLeft: "0.3%", marginTop: "1%" }} onClick={async () => { await this.setState({ banModalBool: false, banValue: "", errorMessage: "", selectedUser: "" }) }}>Vazgeç</button>

                    </Modal>
                    <div class="row page-titles">
                        <div class="col-md-5 align-self-center">
                            <h4 class="text-themecolor">Kullanıcı Görüntüle</h4>
                        </div>
                        <div class="col-md-7 align-self-center text-right">
                            <div class="d-flex justify-content-end align-items-center">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                    <li class="breadcrumb-item active">Kullanıcı Görüntüle</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Select 
                            styles={{ input: styles => ({ ...styles, width: 50 }) }}
                            value={{value: this.state.limit, label: this.state.limit}} 
                            onChange={async (selectedOption) => {await this.setState({ limit: selectedOption.value })}}
                            isMulti={false} options={limitOptions} placeholder="50"
                        />
                        <button class="btn btn-primary" disabled={this.state.disableLoadMore} onClick={this.loadMore}>{this.state.limit} adet daha yükle</button>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Kullanıcı Görüntüle</h4>
                                    <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                                    <div className="table-responsive m-t-40">
                                        <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                                            <thead>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>İsim</th>
                                                    <th>Puan</th>
                                                    <th>Cihaz ID</th>
                                                    <th>Oluşturulma Tarihi</th>
                                                    <th>Banla</th>
                                                    <th>Kullanıcı Raporu</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>İsim</th>
                                                    <th>Puan</th>
                                                    <th>Cihaz ID</th>
                                                    <th>Oluşturulma Tarihi</th>
                                                    <th>Banla</th>
                                                    <th>Kullanıcı Raporu</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                {this.state.users.map(user => {
                                                    return (
                                                        <tr>
                                                            <td>{user.email}</td>
                                                            <td>{user.name}</td>
                                                            <td>{user.point}</td>
                                                            <td>{user.registeredDevice}</td>
                                                            <td><span style={{ display: "none" }}>{dateConvertionForSort(new Date(Number(user.createdAt)))}</span>{dateConvertion(new Date(Number(user.createdAt)))}</td>
                                                            <td>
                                                                {user.userType === "VERIFIED"
                                                                    ?
                                                                    <button class="btn btn-primary" type="button" style={{ marginLeft: "0.3%", marginTop: "1%" }} onClick={async () => { await this.setState({ banModalBool: true, selectedUser: user }) }}>Banlama Seçenekleri</button>
                                                                    :
                                                                    user.userType === "BANNED"
                                                                        ?
                                                                        <button class="btn btn-primary" type="button" style={{ marginLeft: "0.3%", marginTop: "1%" }} onClick={async () => { await this.setState({ banModalBool: true, selectedUser: user }) }}>Banı Kaldır</button>

                                                                        :
                                                                        <h5>Banlanamaz</h5>
                                                                }
                                                            </td>
                                                            <td><span class="text-muted"><i class="fa fa-clock-o"></i> <Link target="_blank" to={{ pathname: `/userReportDetailPage?_id=${user._id}` }}>Görüntüle</Link></span></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


        )
    }

}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%'
    }
};
export default IsSelfWasteReports