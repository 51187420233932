import React,{Component} from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import {dateConvertion} from '../helpers/dateHelpers'
import { call } from '../../services/api';


class MinistryReporting extends Component{

    

    constructor(props) {
        super(props);
        this.state = {
            ministries: [],
            dataCame: false,
            showStats: false,
            selectedMinistryId:"Seç",
            stats: []
        }
        
    
      }

    async componentWillMount(){
        const ministryRequest = {
            query: `query {
                getMinistry{
                    _id
                    district
                }
            }`
        }

        call(ministryRequest).then(resData=>{
            if(resData.errors) 
            {
                console.log("unauthenticated")
            }
            console.log(resData)
           
            
            this.setState({ministries: resData.data.getMinistry,  dataCame:true})
            
        }).catch(err=>{console.log(err)})

    }

    getMinistryStats(){

        if(this.state.selectedMinistryId === "Seç"){
            return
        }

        const reportQuery = {
            query: `query{
            getWasteReportRatesByMinistry(ministryID:"${this.state.selectedMinistryId}"){
                responseRate
                timeAvarage
                totalKonteyner
                wasteTypeGroup{
                    _id
                    count
                }
            }
          }`
        }

        call(reportQuery).then(async resData=>{
            if(resData.errors) 
            {
                this.setState({showStats: true})
                console.log("unauthenticated")
            }
         
            await this.setState({stats: resData.data.getWasteReportRatesByMinistry, showStats: true})
            console.log(this.state.stats)
        }).catch(err=>{console.log(err); this.setState({showStats: true})
    })

    }
 

    render(){
        if(!this.state.dataCame){
            return(
                <div class="page-wrapper">
                <div class="container-fluid">
                <h1>Bekleyin...</h1>
                </div>
                </div>
            )
        }else if(!this.state.showStats){
            return (

                <div class="page-wrapper">
                    <div class="container-fluid">
                    <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Belediye Filtrele</h4>
                        <select class="form-control-lg " onChange={async (e) => {await this.setState({ selectedMinistryId: e.target.value })}}>
                        <option value="Seç">Seç</option>
                            {this.state.ministries.map(ministry => {
                                return(
                                    <option value={ministry._id}>{ministry.district}</option>
                                )
                            })}
                          
                        </select>
                        <br/>
                        <button class="btn btn-primary" type="button" style={{marginTop:"4%"}} onClick={async () => {this.getMinistryStats()}}>Filtreyi Uygula</button>
                    </div>
                    </div>
                    </div>
                </div>    
            )    
        }
        return (

        <div class="page-wrapper">
            <div class="container-fluid">
                <div class="row page-titles">
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Belediye Filtrele</h4>
                        <select class="form-control-lg " onChange={async (e) => {await this.setState({ selectedMinistryId: e.target.value })}}>
                        <option value="Seç">Seç</option>
                            {this.state.ministries.map(ministry => {
                                return(
                                    <option value={ministry._id}>{ministry.district}</option>
                                )
                            })}
                            
                        </select>
                        <br/>
                        <button class="btn btn-primary" type="button" style={{marginTop:"4%"}} onClick={async () => {this.getMinistryStats()}}>Filtreyi Uygula</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row p-t-10 p-b-10">                         
                                <div class="col p-r-0">
                                    <h1 class="font-light">%{this.state.stats.responseRate}</h1>
                                    <h6 class="text-muted">Bildirimlere Cevap Verme Oranı</h6></div>      
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div class="col-lg-4 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row p-t-10 p-b-10">
                                <div class="col p-r-0">
                                    <h1 class="font-light">{this.state.stats.timeAvarage} Saat</h1>
                                    <h6 class="text-muted">Bildirimlere Cevap Verme Süresi(Ortalama)</h6></div>      
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div class="col-lg-4 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="row p-t-10 p-b-10">
                                <div class="col p-r-0">
                                    <h1 class="font-light">{this.state.stats.totalKonteyner}</h1>
                                    <h6 class="text-muted">Toplam Konteyner Sayısı</h6></div>      
                                </div>
                            </div>
                        </div>
                    </div> 
                </div> 
                {this.state.stats.wasteTypeGroup.map(info => {
                    return(
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row p-t-10 p-b-10">                         
                                        <div class="col p-r-0">
                                            <h1 class="font-light">{info.count} adet bildirim için</h1>
                                            <h6 class="text-muted">{info._id}</h6></div>      
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    )
                })}               
            </div>
        </div>  
         
        )
    }
}

export default MinistryReporting