import {SET_CURRENT_USER, SET_MUNICIPALITY} from '../actiontypes'

const DEFAULT_STATE = {
    isAuthenticated : false,
    user:{},
    municipality: {value: "Tümü", label: "Tümü"}
}

export default (state=DEFAULT_STATE , action)=>{
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated : !!Object.keys(action.user).length,
                user:action.user
            }
        case SET_MUNICIPALITY: 
            return {
                ...state,
                municipality:action.municipality
            }
        default:
            return state;
    }
}