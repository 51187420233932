import React,{Component, } from 'react'
import {call} from '../services/api'
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

class AddKonteynerExcel extends Component {


    constructor(props){
        super(props)
        this.state = {
            rows: [],
            cols: [],
            errMessage: "",
            successMessage: "",
            konteynerAdding: false,
            selectedMinistryId: "Seç",
            ministries:[],
        }
    }
    async componentWillMount(){
        const ministryRequest = {
            query: `query {
                getMinistry{
                    _id
                    district
                }
            }`
        }

        call(ministryRequest).then(resData=>{
            if(resData.errors) 
            {
                console.log("unauthenticated")
            }
            console.log(resData)
           
            
            this.setState({ministries: resData.data.getMinistry,  dataCame:true})
            
        }).catch(err=>{console.log(err)})
    }

    fileHandler = (event) => {
        let fileObj = event.target.files[0];
        let fixedArray = []
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
         
          if(err){
            console.log(err);            
          }
          else{
            for(let i = 0; i < resp.rows.length; i++){
                for(let k = 0; k < resp.rows[i].length; k ++){
                    if(k === resp.rows[i].length -1){
                        resp.rows[i][k] = resp.rows[i][k].toString() + ";"
                    }else {
                        resp.rows[i][k] = resp.rows[i][k].toString()
                    }                 
                }
                fixedArray.push(resp.rows[i])
            }

            this.setState({
              rows: fixedArray
            });
          }
        });   
      }

      addKonteyner = () => {
            console.log("here")
            const selectedMinistry = this.state.selectedMinistryId

          if( this.state.rows.length === 0){
              this.setState({errMessage: "Lütfen excel dosyasını yükleyin"})
              return 
          }else if(selectedMinistry === "Seç"){
            this.setState({successMessage: "Lütfen belediyeyi seçiniz"})
            return 
          }else {
              
              this.setState({errMessage:"", konteynerAdding: true})
            
              const requestBody = {
                query: `mutation{
                    createKonteynersExcel(konteyners:[["${this.state.rows}"]], ministryId:"${selectedMinistry}"){
                        _id
                        latitude
                        longitude
                        mahalle
                        sokak
                        type
                        konteynerType
                        konteynerNumber
                        registeredMinistry
                    }
                }`
              }

              call(requestBody)
              .then(res => {
                if(res.errors){
                    this.setState({successMessage:"Konteyner eklenemedi"})
                    this.setState({konteynerAdding: false})
                }
                this.setState({konteynerAdding: false})
                this.setState({successMessage:"Konteyner başarıyla eklendi"})
              })
              .catch(err => {
                this.setState({konteynerAdding: false})
                this.setState({successMessage:"Konteyner eklenemedi"})
            })
          }

      }

      render(){
          if(this.state.konteynerAdding){
            return(
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Konteynerler ekleniyor. Bu işlem biraz zaman alabilir...</h1>
                    </div>
                </div>
              )
          }
          return (
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h1>Konteyner Ekle</h1>
                                        <form className="mt-4"> 
                                            <div className="form-group">
                                                <label>Belediye Seç</label><br></br>
                                                <select class="form-control-lg " onChange={async (e) => {await this.setState({ selectedMinistryId: e.target.value })}}>
                                                    <option value="Seç">Seç</option>
                                                        {this.state.ministries.map(ministry => {
                                                            return(
                                                                <option value={ministry._id}>{ministry.district}</option>
                                                            )
                                                        })}                                          
                                                </select>    
                                                <h4>Excel Dosyasını Yükleyin</h4>
                                                <input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} required/>
                                            </div>
                                            <p style={{color:"red"}}>{this.state.errMessage}</p>
                                        </form>
                                        <p style={this.state.successMessage === "Konteyner eklenemedi" ? {color:"red"} : {color:"green"}}>{this.state.successMessage}</p>
                                        <button onClick={this.addKonteyner} className="btn btn-primary">Konteynerler Ekle</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          )
      }
}

export default AddKonteynerExcel