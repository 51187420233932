import React, { Component } from 'react'
import { saveAs } from 'file-saver';
const JSZip = require("jszip")
const JSZipUtils = require("jszip-utils")

class TableComponent extends Component {
    constructor(props) {
        super(props)
    }
    generateZIP(images, zFileName) {
        var zip = new JSZip();
        var count = 0;
        var zipFilename = zFileName + ".zip";
        let links = images
        let undefinedNumber = 0
        links.forEach(function (url, i) {
            if (links[i] !== undefined) {
                var filename = links[i];
                filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpsapi.kollektapp.org", "");
                // loading a file and add it in a zip file
                JSZipUtils.getBinaryContent(url, function (err, data) {
                    if (err === null) {
                        zip.file(filename, data, { binary: true });
                        count++;

                        if (count + undefinedNumber == links.length) {
                            zip.generateAsync({ type: 'blob' }).then(function (content) {
                                saveAs(content, zipFilename);
                            });
                        }
                    }else{
                        count++
                        return
                    }
                });
            }else{
                undefinedNumber++
            }
        });
    }

    renderImageDownload(){
        if(this.props.images){
            if(this.props.images instanceof Array){
                if(this.props.images.length !== 0 ){
                    return <button className="btn btn-primary mr-1" onClick={() => this.generateZIP(this.props.images, this.props.pageHeader)}>Atık resimlerini indir</button>
                }
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{this.props.pageHeader}</h4>
                                <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                                {this.renderImageDownload()}
                                <div className="table-responsive m-t-40">
                                    <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                                        <thead>
                                            <tr>
                                                {this.props.tableHeadersArray.map(headerName => {
                                                    return (
                                                        <th>{headerName}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                {this.props.tableHeadersArray.map(headerName => {
                                                    return (
                                                        <th>{headerName}</th>
                                                    )
                                                })}
                                            </tr>
                                        </tfoot>
                                        <tbody>

                                            {this.props.tableContentArray.map(contentArr => {
                                                return (
                                                    <tr>
                                                        {contentArr.map(content => {
                                                            return (
                                                                <td>{content}</td>
                                                            )
                                                        })}
                                                    </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default TableComponent