import React from 'react';
import Modal from './modalWithStyles';
import DeleteModalBody from '../modalBodies/deleteModalBody';

const DeleteModal = ({
    isOpen,
    errorMessage,
    successMessage,
    onDeleteClick,
    onCloseClick,
    deleteButtonDisabled
}) => (
    <Modal
        isOpen={isOpen}
    >
        <DeleteModalBody
            errorMessage={errorMessage}
            successMessage={successMessage}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
            deleteButtonDisabled={deleteButtonDisabled}
        />

    </Modal>
);

export default DeleteModal;