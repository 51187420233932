import React, { Component } from 'react'
import Modal from 'react-modal';
import Button from '../components/button/button';
import DeleteModal from '../components/modals/deleteModal';
import { call } from '../../services/api'
import { changeCarouselBody } from '../../api/apiAnnouncement';

class Announcements extends Component {

    constructor(props) {
        super(props)


        this.state = {
            infos: [],
            dataCame: false,
            showAll: true,
            selectedInfo: null,
            resultMessage: "",
            modalBool: false,
            deleteModalBool: false,
            activeValue: "",
            errorMessage: "",
            successMessage: "",
            selectedInfo: null,
            dataTableId: "example23",
            changeCarousel: false,
            selectedAnnouncement: null
        }

        this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
        this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
        this.handleAnnouncementDelete = this.handleAnnouncementDelete.bind(this);
        this.handleDeletedAnnouncementChange = this.handleDeletedAnnouncementChange.bind(this);
        this.titleRu = ""
        this.title = ""
        this.titleEn = ""
        this.titleTr = ""
    }




    componentWillMount() {
        const requestBody = {
            query: `
                query{
                    carouselsAll
                    {
                        _id
                        title
                        illustration
                        active
                        titleTr
                        titleRu
                        titleEn
                    }
                }
            `
        }



        call(requestBody).then(async resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }
            console.log(resData)


            await this.setState({ infos: resData.data.carouselsAll, dataCame: true })

        }).catch(err => { console.log(err) })
    }



    componentDidUpdate() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `$('#example23').DataTable({
                            dom: 'Bfrtip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ]
                    });
                    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
                `;
        if (this.state.dataCame) {
            document.body.appendChild(s);
        }
    }

    showStatus(stat) {
        if (stat === "bekliyor") {
            return "beklemede"
        }
    }
    
    handleDeleteModalOpen = announcement => () => {
        this.setDeleteModalOpenState(announcement)
    }

    setDeleteModalOpenState(announcement) {
        this.setState({ deleteModalBool: true, dataTableId: "2", selectedAnnouncement: announcement })
    }

    handleDeleteModalClose() {
        this.setState({
            deleteModalBool: false,
            dataTableId: "3",
            selectedAnnouncement: null,
            errorMessage: '',
            successMessage: ''
        })
    }

    async setActive() {

        if (this.state.activeValue === "") {
            return
        }
        const requestBody = {
            query: `
                mutation{
                    changeCarouselActive(_id:"${this.state.selectedInfo._id}", activeBool:${this.state.activeValue})
                    {
                        _id
                    }
                }
            `}


        call(requestBody).
            then(resData => {
                if (resData.errors) {
                    this.setState({ errorMessage: "İşlem başarısız" })
                } else {
                    this.setState({ errorMessage: "İşlem başarılı" })
                }
            })
            .catch(err => { console.log(err); this.setState({ errorMessage: "İşlem başarısız" }) })
    }

    async handleAnnouncementDelete() {
        const { selectedAnnouncement } = this.state;

        const result = await changeCarouselBody(selectedAnnouncement._id);
        if (result) {
            this.setState({ successMessage: 'Duyuru başarıyla silindi.' })
            this.handleDeletedAnnouncementChange()
        } else {
            this.setState({ errorMessage: 'Duyuru silinemedi.' })
        }
    }
    handleDeletedAnnouncementChange() {
        const { selectedAnnouncement, infos } = this.state;
        const newAnnouncements = infos.map((announcement) => {
            if (announcement === selectedAnnouncement) {
                announcement.deleted = true;
            }
            return announcement;
        })
        this.setState({ infos: newAnnouncements, selectedAnnouncement: { ...selectedAnnouncement, deleted: true } });
    }
    changeCarouselInfo() {
        const changeCarouselBody = {
            query: `
                mutation{
                    updateCarouselInfo(_id:"${this.state.selectedInfo._id}",title: "${this.title}", titleRu: "${this.titleRu}", titleEn: "${this.titleEn}", titleTr:"${this.titleTr}")
                    {
                    _id
                    }
                }
            `}


        call(changeCarouselBody).
            then(resData => {
                if (resData.errors) {
                    this.setState({ errorMessage: "İşlem başarısız" })
                } else {
                    this.setState({ errorMessage: "İşlem başarılı" })
                }
            })
            .catch(err => { console.log(err); this.setState({ errorMessage: "İşlem başarısız" }) })
    }

    setCarouselInfo = (selectedCaro) => {
        this.titleRu = selectedCaro.titleRu
        this.title = selectedCaro.title
        this.titleEn = selectedCaro.titleEn
        this.titleTr = selectedCaro.titleTr
    }

    render() {
        const {
            deleteModalBool,
            modalBool,
            errorMessage,
            successMessage,
            selectedAnnouncement
        } = this.state;

        if (!this.state.dataCame) {
            return (
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Bekleyin...</h1>
                    </div>
                </div>
            )
        } else if (!this.state.changeCarousel) {

            return (

                <div class="page-wrapper">
                    <div class="container-fluid">
                        <DeleteModal
                            isOpen={deleteModalBool}
                            errorMessage={errorMessage}
                            successMessage={successMessage}
                            onDeleteClick={this.handleAnnouncementDelete}
                            onCloseClick={this.handleDeleteModalClose}
                            deleteButtonDisabled={selectedAnnouncement && selectedAnnouncement.deleted}
                        />
                        <Modal
                            isOpen={modalBool}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >

                            <form>
                                <select
                                    onChange={async (e) => { await this.setState({ activeValue: e.target.value }) }}
                                    style={{ width: "100%", height: "100%" }} name="activation" size="2" multiple
                                >
                                    <option value={true}>Aktifleştir.</option>
                                    <option value={false}>DeAktifleştir</option>
                                </select>
                            </form>
                            <h4 style={this.state.errorMessage === "İşlem başarısız" ? { color: "red" } : { color: "green" }}>{this.state.errorMessage}</h4>
                            <button class="btn btn-primary" type="button" style={{ marginRight: "0.3%", marginTop: "1%" }} onClick={async () => { this.setActive() }}>Onayla</button>
                            <button class="btn btn-primary" type="button" style={{ marginLeft: "0.3%", marginTop: "1%" }} onClick={async () => { await this.setState({ modalBool: false, activeValue: "", errorMessage: "", dataTableId: "example22" }) }}>Geri</button>

                        </Modal>
                        <div class="row page-titles">
                            <div class="col-md-5 align-self-center">
                                <h4 class="text-themecolor">Duyuruları Yönet</h4>
                            </div>
                            <div class="col-md-7 align-self-center text-right">
                                <div class="d-flex justify-content-end align-items-center">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                        <li class="breadcrumb-item active">Duyuruları Yönet</li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Duyuruları Yönet</h4>
                                        <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                                        <div className="table-responsive m-t-40">
                                            <table id={this.state.dataTableId} className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Başlık</th>
                                                        <th>Resim</th>
                                                        <th>Aktiflik Durumu</th>
                                                        <th>Aktiflik Düzenle</th>
                                                        <th>Bilgi Değiştir</th>
                                                        <th>Sil</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>Başlık</th>
                                                        <th>Resim</th>
                                                        <th>Aktiflik Durumu</th>
                                                        <th>Aktiflik Düzenle</th>
                                                        <th>Bilgi Değiştir</th>
                                                        <th>Sil</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    {this.state.infos.map(info => {
                                                        return (
                                                            <tr>
                                                                <td>{info.title}</td>
                                                                <td><img src={info.illustration} style={{ width: 50, height: 50 }}></img></td>
                                                                <td>{info.active === true ? "Aktif" : "Pasif"}</td>
                                                                <td><span class="text-muted"><button class="btn btn-primary" onClick={async () => { await this.setState({ dataTableId: "1", selectedInfo: info, modalBool: true }) }}>Aktiflik Düzenle</button></span></td>
                                                                <td><span class="text-muted"><button class="btn btn-primary" onClick={async () => { this.setCarouselInfo(info); await this.setState({ selectedInfo: info, changeCarousel: true, }) }}>Bilgi Değiştir</button></span></td>
                                                                <td>
                                                                    <span class="text-muted">
                                                                        <Button onClick={this.handleDeleteModalOpen(info)} disabled={info.deleted}>{info.deleted ? 'Silindi' : 'Sil'}</Button>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="row page-titles">
                            <div className="col-md-5 align-self-center">
                                <h4 className="text-themecolor">Duyuru Bilgisi Güncelleme</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Duyuru Bilgilerini Güncelleyin</h4>

                                        <form className="mt-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">başlık</label>
                                                <input type="text" className="form-control"
                                                    onChange={(e) => { e.target.value.trim().length === 0 ? this.title = this.state.selectedInfo.title : this.title = e.target.value }}
                                                    placeholder={this.title}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">başlık TR</label>
                                                <input type="text" className="form-control"
                                                    onChange={(e) => { e.target.value.trim().length === 0 ? this.titleTr = this.state.selectedInfo.titleTr : this.titleTr = e.target.value }}
                                                    placeholder={this.titleTr} />
                                            </div>

                                            <div className="form-group">
                                                <label>başlık EN</label>
                                                <input type="text" class="form-control"
                                                    onChange={(e) => { e.target.value.trim().length === 0 ? this.titleEn = this.state.selectedInfo.titleEn : this.titleEn = e.target.value }}
                                                    placeholder={this.titleEn} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">title RU</label>
                                                <input type="text" className="form-control"
                                                    onChange={(e) => { e.target.value.trim().length === 0 ? this.titleRu = this.state.selectedInfo.titleRu : this.titleRu = e.target.value }}
                                                    placeholder={this.titleRu} />
                                            </div>
                                            <p style={this.state.resultMessage === "Kurum güncellenemedi" ? { color: "red" } : { color: "green" }}>{this.state.resultMessage}</p>
                                        </form>
                                        <h4 style={this.state.errorMessage === "İşlem başarısız" ? { color: "red" } : { color: "green" }}>{this.state.errorMessage}</h4>
                                        <button onClick={() => { this.changeCarouselInfo() }} className="btn btn-primary">Duyuru Bilgisi Güncelle</button><br></br>
                                        <button onClick={() => { this.setState({ changeCarousel: false }) }} className="btn btn-primary">Geri</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%'
    }
};

export default Announcements