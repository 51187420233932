import { call } from '../services/api';

export const changeCarouselBody = (_id) => {
    const deleteAnnouncement = {
        query: `
            mutation{
                deleteAnnouncement(_id:"${_id}")
            }
    `}

    const result = call(deleteAnnouncement)
        .then(resData => {
            if (resData) {
                return true;
            }

            return false;
        })
        .catch(err => false )

    return result;
}