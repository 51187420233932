import React, { Component, } from 'react'
import { call } from '../../services/api'
import queryString from 'query-string'
import TableComponent from '../components/tableScreen/tableComponent'
import { dateConvertion, dateConvertionForSort } from '../helpers/dateHelpers'
import { ShowKonteynerNameAccordingToType } from '../helpers/markerHelpers'
import { getWasteStatus, isDeleted, deleteReport } from '../components/tableScreen/componentHelpers/generalHelpers'
import { Link } from 'react-router-dom'
import { createDataTable } from '../components/tableScreen/componentHelpers/createDataTable'
import Modal from 'react-modal';
import { getScatterChart } from '../components/scatterChart'

class UserReportDetailPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataCame: false,
            comingWasteArray: [],
            user: [],
            deletedReportId: "",
            deletedReportIds: [],
            cancelModalBool: false,
            chartData: []
        }
    }

    async componentWillMount() {
        let userId = queryString.parse(this.props.location.search)

        const requestBody = {
            query: `
            query{
                getUserDetailByWasteReport(userID:"${userId._id}")
                {
                   user{
                       email
                       name
                   }
                   wasteReports{
                        wasteTypeGroup{
                         _id
                         count   
                        }
                       wasteReports{
                           _id
                           waste_status
                           images
                           createdAt
                           wasteType
                           statusExplanation
                           registeredMinistry{district}
                       }
                   }
                }
            }
        `
        }
        call(requestBody)
            .then(async res => {
                if (!res.errors) {
                    await this.setState({ comingWasteArray: res.data.getUserDetailByWasteReport.wasteReports, user: res.data.getUserDetailByWasteReport.user, dataCame: true })
                    if (this.state.comingWasteArray.wasteReports.length !== 0) {
                        this.createChartData(res.data.getUserDetailByWasteReport.wasteReports.wasteReports)
                    }
                    createDataTable()
                }
            })
            .catch(err => console.log(err))
    }

    async createChartData(wasteReports) {
        let timestamp
        let timest
        let dataModel = []
        await wasteReports.map(rep => {
            timestamp = rep._id.toString().substring(0, 8)
            timest = new Date(parseInt(timestamp, 16) * 1000)
            dataModel.push({ x: new Date(timest.getFullYear(), timest.getMonth(), timest.getDate()), y: 2 })
        })
        dataModel.push({ x: new Date(timest.getFullYear(), timest.getMonth(), timest.getDate()), y: 1 })
        await this.setState({ chartData: dataModel })
    }

    delete() {
        deleteReport(this.state.deletedReportId, this.state.deletedReportIds)
            .then(async result => {
                await this.setState({
                    deleteSuccessMessage: result.deleteSuccessMessage,
                    deletedReportIds: result.deletedReportIds,
                    errorMessage: result.errorMessage
                })
            }
            )
            .catch(async result => {
                await this.setState({
                    deleteSuccessMessage: result.deleteSuccessMessage,
                    errorMessage: result.errorMessage
                })
            })
    }

    render() {

        if (this.state.dataCame) {
            return (
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <Modal
                            isOpen={this.state.cancelModalBool}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <h2>Bildirimi silmek istediğinize emin misiniz?</h2>
                            <h4 style={{ color: "red" }}>{this.state.errorMessage}</h4>
                            <h4 style={{ color: "green" }}>{this.state.deleteSuccessMessage}</h4>
                            <button class="btn btn-primary" type="button" style={{ marginRight: "0.3%", marginTop: "1%" }} onClick={async () => { this.delete() }}>Sil</button>
                            <button class="btn btn-primary" type="button" style={{ marginLeft: "0.3%", marginTop: "1%" }} onClick={async () => { await this.setState({ deleteSuccessMessage: "", errorMessage: "", deletedKonteynerId: "", cancelModalBool: false, }) }}>Kapat</button>
                        </Modal>

                        <div class="row page-titles">
                            <div class="col-md-5 align-self-center">
                                <h4 class="text-themecolor">İsim: {this.state.user.name}   Email: {this.state.user.email}</h4>
                            </div>
                            <div class="col-md-7 align-self-center text-right">
                                <div class="d-flex justify-content-end align-items-center">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                        <li class="breadcrumb-item active">Kullanıcı Analizi</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            {this.state.comingWasteArray.length !== 0
                                ?
                                this.state.comingWasteArray.wasteTypeGroup.map(info => {
                                    return (
                                        <div class="col-lg-4 col-md-6">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row p-t-10 p-b-10">
                                                        <div class="col p-r-0">
                                                            <h1 class="font-light">{info.count}</h1>
                                                            <h6 class="text-muted">{info._id}</h6></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div class="col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row p-t-10 p-b-10">
                                                <div class="col p-r-0">
                                                    <h1 class="font-light">Rapor Kaydı Yok</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {getScatterChart([{
                                label: 'Tarihe göre bildirimler',
                                data: this.state.chartData,
                                backgroundColor: "black"
                            }])}

                        </div>
                        <TableComponent
                            tableHeadersArray={
                                [
                                    "Durum",
                                    "Belediye",
                                    "Atık Tipleri",
                                    "Bildirim Tarihi",
                                    "Görüntüle",
                                    "Sil"
                                ]}
                            tableContentArray={
                                this.state.comingWasteArray.wasteReports.map(rep => {
                                    return (
                                        [
                                            getWasteStatus(rep.waste_status, rep.statusExplanation),
                                            rep.registeredMinistry !== null ? rep.registeredMinistry.district : "Belediye bilgisi yok",
                                            ShowKonteynerNameAccordingToType(rep.wasteType),
                                            <><span style={{ display: "none" }}>{dateConvertionForSort(new Date(Number(rep.createdAt)))}</span>{dateConvertion(new Date(Number(rep.createdAt)))}</>,
                                            <><span class="text-muted"><i class="fa fa-clock-o"></i> <Link target="_blank" to={{ pathname: `/wasteReportDetail?_id=${rep._id}`, aboutProps: { report: rep } }}>Görüntüle</Link></span></>,
                                            <><span class="text-muted"><i class="fa fa-clock-o"></i> <button class="btn btn-primary" disabled={isDeleted(this.state.deletedReportIds, rep._id, true)} onClick={async () => { await this.setState({ deletedReportId: rep._id, cancelModalBool: true }) }}>{isDeleted(this.state.deletedReportIds, rep._id, false)}</button></span></>
                                        ]
                                    )
                                })
                            }
                            images={
                                this.state.comingWasteArray.wasteReports.map(rep => {
                                    if (rep.images instanceof Array) {
                                        if (rep.images.length !== 0) {
                                            if (rep.images[0] !== undefined)
                                                return "https://api.kollektapp.org/" + rep.images[0].slice(10)
                                        }
                                    }
                                })
                            }
                            pageHeader={this.state.user.name + " Tüm Bildirimler"}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <h1>wait</h1>
            )
        }
    }
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%'
    }
};
export default UserReportDetailPage