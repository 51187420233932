import {call} from '../../../../services/api'


export default (query, resultvarName) => {
    return new Promise((resolve, reject) => {
        const requestBody = {
            query: query
        }
    
        call(requestBody)
        .then(async resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }
            resolve({ wasteReports: resData.data[resultvarName], showWasteReports: true, dataCame: true })
        }).catch(err => { console.log(err); resolve({ wasteReports: [], dataCame: true, showWasteReports: false })})     
})
}
