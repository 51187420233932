import React, { Component } from 'react'
import Select from 'react-select';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { showKonteynerStatusName, showKonteynerTypeName, ShowKonteynerNameAccordingToType } from './helpers/markerHelpers'
import Modal from 'react-modal';
import { call } from '../services/api'
import { dateConvertion, dateConvertionForSort } from './helpers/dateHelpers'
import { limitOptions } from './components/wasteReportComponent';
import { createDataTable } from './components/tableScreen/componentHelpers/createDataTable';


class Konteyners extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            mahalle: "",
            sokak: "",
            konteynerTipi: "",
            konteynerAdet: "",
            konteynerDurum: "",
            konteynerAtilacaklar: "",
            konteyner_image: "",
            konteyners: [],
            dataCame: false,
            ministries: [],
            selectedMinistryId: "Tümü",
            showKonteyners: false,
            deletedKonteynerId: "",
            deleteSuccessMessage: "",
            errorMessage: "",
            updateKonteynerInfo: false,
            updateErrorMessage: "",
            selectedInfo: "",
            resultMessage: "",
            deletedKonteynerArray: [],
            mapDataCame: false,
            mapLatLong: [],
            skip: 0,
            limit: 50
        };
        this.sokak = ""
        this.mahalle = ""
    }

    konteynerStringConverter(types) {
        var typeString = ""
        types.forEach(type => {

            switch (type) {
                case 1:

                    typeString = typeString + "Pet Şişe - "
                    break;
                case 2:
                    typeString = typeString + "Cam - "
                    break;
                case 3:
                    typeString = typeString + "Kağit/Karton - "
                    break;
                case 4:
                    typeString = typeString + "Teneke Kutu/Metal - "
                    break;
                case 5:
                    typeString = typeString + "Mutfak/Yemek Artığı - "
                    break;
                case 6:
                    typeString = typeString + "İnşaat/Yıkıntı - "
                    break;
                case 7:
                    typeString = typeString + "Bebek Bezi - "
                    break;
                case 8:
                    typeString = typeString + "Park Bahçe Atıkları - "
                    break;
                case 9:
                    typeString = typeString + "Araba Lastiği - "
                    break;
                case 10:
                    typeString = typeString + "Eski Ev Eşyaları - "
                    break;
                case 11:
                    typeString = typeString + "Cam Kırıkları ve Süprüntüler - "
                    break;
                case 12:
                    typeString = typeString + "Tıbbi Atık - "
                    break;
                case 13:
                    typeString = typeString + "Elektronik Atık - "
                    break;
                case 14:
                    typeString = typeString + "Bitkisel Atık Yağ - "
                    break;
                case 15:
                    typeString = typeString + "Tekstil Atığı - "
                    break;
                case 16:
                    typeString = typeString + "Meyve/Sebze Atıkları - "
                    break;
                case 17:
                    typeString = typeString + "Pil - "
                    break;
                case 18:
                    typeString = typeString + "Atık İlaç - "
                    break;
                case 19:
                    typeString = typeString + "Akü/Elektrikli Araç Bataryası - "
                    break;
                case 20:
                    typeString = typeString + "Atık Motor Yağı - "
                    break;
                case 21:
                    typeString = typeString + "Maske/Eldiven - "
                    break;
                default:
                    break;
            }

        });
        return typeString
    }

    convertKonteynerTypeToString(konteynerType) {
        switch (konteynerType) {
            case 1:
                return "Belediye"
                break;
            case 2:
                return "Cam Kumbarası"
                break;
            case 3:
                return "Pet Şişe Kafesi"
                break
            case 4:
                return "Karışık Ambalaj"
                break;
            case 5:
                return "Tekli Çoklu Özel"
                break
            case 6:
                return "Atık Getirme Merkezi"
                break
            case 7:
                return "Maske/Eldiven"
                break
            default:
                break;
        }
    }


    componentDidMount() {
        const ministryRequest = {
            query: `query {
                getMinistry{
                    _id
                    district
                }
            }`
        }

        call(ministryRequest).then(resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }
            console.log(resData)


            this.setState({ ministries: resData.data.getMinistry, dataCame: true })

        }).catch(err => { console.log(err) })


    }

    

    setFilter = async (doesMunicipalityChange) => {
        const { skip, limit } = this.state;
        await this.setState({ dataCame: false })
        const requestBody = {
            query: `
                query{
                    konteyners(ministryId:"${this.state.selectedMinistryId}", skip:${doesMunicipalityChange ? 0 : skip}, limit:${limit})
                    {
                        _id
                        konteynerType
                        konteynerNumber
                        type
                        emptyFrequency
                        status
                        createdAt
                        mahalle
                        sokak
                        latitude
                        longitude
                        konteyner_image
                    }
                }
            `
        }

        const ministryRequest = {
            query: `
                query{
                    getMinistryById(ministryId:"${this.state.selectedMinistryId}")
                    {
                        latitude
                        longitude
                    }
                }
            `
        }
        await this.setState({ mapDataCame: false })
        if (this.state.selectedMinistryId !== "Tümü") {
            call(ministryRequest).then(resData => {
                if (resData.errors) {
                    console.log("unauthenticated")
                }
                console.log(resData)
                this.setState({ mapLatLong: resData.data.getMinistryById, mapDataCame: true })
            }).catch(err => {
                console.log(ministryRequest); this.setState({ mapLatLong: { latitude: 36.59778, longitude: 30.56056 }, mapDataCame: true })
            })
        } else {
            this.setState({ mapLatLong: { latitude: 36.59778, longitude: 30.56056 }, mapDataCame: true })
        }
        call(requestBody).then(async resData => {
            let newKonteyners = doesMunicipalityChange ? [] : this.state.konteyners;
            let newSkip = doesMunicipalityChange ? 0 : skip;
            if (resData.errors) {
                this.setState({ dataCame: true })
            } else if(resData && resData.data && resData.data.konteyners) {
                newKonteyners = [...newKonteyners, ...resData.data.konteyners];
                newSkip += resData.data.konteyners.length;
            }
            console.log(resData)
            await this.setState({ konteyners: newKonteyners, dataCame: true, showKonteyners: true, skip: newSkip })
            createDataTable();
        }).catch(err => {
            console.log(err); this.setState({ dataCame: true })
        })
    }

    onMarkerClick = async (props, marker, e) => {

        await this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            mahalle: marker.name.mahalle,
            konteyner_image: marker.name.konteyner_image,
            sokak: marker.name.sokak,
            konteynerTipi: showKonteynerTypeName(marker.name.konteynerType),
            konteynerAdet: marker.name.konteynerNumber,
            konteynerDurum: showKonteynerStatusName(marker.name.status),
            konteynerAtilacaklar: ShowKonteynerNameAccordingToType(marker.name.type)
        });
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    deleteKonteyner() {

        const requestBody = {
            query: `
                mutation{
                    deleteContainer(konId:"${this.state.deletedKonteynerId}")
                    {
                        _id
                    }
                }
            `
        }

        call(requestBody).then(async resData => {
            if (resData.errors) {
                this.setState({ errorMessage: "Konteyner Silinemedi" })
                console.log("unauthenticated")
            }
            await this.setState({ deleteSuccessMessage: "Konteyner Silindi" })
            let newKontArray = this.state.konteyners
            let deletedKonteynerArray = this.state.deletedKonteynerArray
            deletedKonteynerArray.push(this.state.deletedKonteynerId)
            await this.setState({ deletedKonteynerArray })
        }).catch(err => {
            console.log(err); this.setState({ errorMessage: "Konteyner Silinemedi" })
        })
    }

    isDeleted(repId, forActive) {
        let disable = false
        if (forActive) {
            if (this.state.deletedKonteynerArray.length === 0) {
                disable = false
            } else {
                for (let i = 0; i < this.state.deletedKonteynerArray.length; i++) {
                    if (repId === this.state.deletedKonteynerArray[i]) {
                        disable = true
                        break
                    } else {
                        disable = false
                    }
                }
            }
        } else {
            if (this.state.deletedKonteynerArray.length === 0) {
                disable = "Sil"
            } else {
                for (let i = 0; i < this.state.deletedKonteynerArray.length; i++) {
                    if (repId === this.state.deletedKonteynerArray[i]) {
                        disable = "Silindi"
                        break
                    } else {
                        disable = "Sil"
                    }
                }
            }
        }

        return disable
    }

    changeKonteynerInfo() {
        const changeKonteynerBody = {
            query: `
                mutation{
                    updateKonteynerInfo(_id:"${this.state.selectedInfo._id}",sokak: "${this.sokak}", mahalle: "${this.mahalle}")
                    {
                    _id
                    }
                }
            `}


        call(changeKonteynerBody).
            then(resData => {
                if (resData.errors) {
                    this.setState({ updateErrorMessage: "İşlem başarısız" })
                } else {
                    this.setState({ updateErrorMessage: "İşlem başarılı" })
                }
            })
            .catch(err => { console.log(err); this.setState({ updateErrorMessage: "İşlem başarısız" }) })
    }
    setKonteynerInfo = (selectedKon) => {
        this.sokak = selectedKon.sokak
        this.mahalle = selectedKon.mahalle
    }

    render() {
        if (!this.state.dataCame) {
            return (
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Bekleyin...</h1>
                    </div>
                </div>
            )
        }
        else if (this.state.updateKonteynerInfo) {
            return (
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <div className="row page-titles">
                            <div className="col-md-5 align-self-center">
                                <h4 className="text-themecolor">Konteyner Bilgisi Güncelleme</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Konteyner Bilgilerini Güncelleyin</h4>

                                        <form className="mt-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">mahalle</label>
                                                <input type="text" className="form-control"
                                                    onChange={(e) => { e.target.value.trim().length === 0 ? this.mahalle = this.state.selectedInfo.mahalle : this.mahalle = e.target.value }}
                                                    placeholder={this.mahalle}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputPassword1">sokak</label>
                                                <input type="text" className="form-control"
                                                    onChange={(e) => { e.target.value.trim().length === 0 ? this.sokak = this.state.selectedInfo.sokak : this.sokak = e.target.value }}
                                                    placeholder={this.sokak} />
                                            </div>

                                            <p style={this.state.resultMessage === "Kurum güncellenemedi" ? { color: "red" } : { color: "green" }}>{this.state.resultMessage}</p>
                                        </form>
                                        <h4 style={this.state.updateErrorMessage === "İşlem başarısız" ? { color: "red" } : { color: "green" }}>{this.state.updateErrorMessage}</h4>
                                        <button onClick={() => { this.changeKonteynerInfo() }} className="btn btn-primary">Konteyner Bilgisi Güncelle</button><br></br>
                                        <button onClick={() => { this.setState({ updateKonteynerInfo: false }) }} className="btn btn-primary">Geri</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (!this.state.showKonteyners) {
            return (

                <div class="page-wrapper">
                    <div class="container-fluid">
                        <div class="row page-titles">
                            <div class="col-md-5 align-self-center">
                                <h4 class="text-themecolor">Belediye Filtrele</h4>
                                <select class="form-control-lg " onChange={async (e) => { await this.setState({ selectedMinistryId: e.target.value }) }}>
                                    <option value="Tümü">Tümü</option>
                                    {this.state.ministries.map(ministry => {
                                        return (
                                            <option value={ministry._id}>{ministry.district}</option>
                                        )
                                    })}

                                </select>
                                <br />
                                <button class="btn btn-primary" type="button" style={{ marginTop: "4%" }} onClick={async () => { this.setFilter(true) }}>Filtreyi Uygula</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (

                <div class="page-wrapper">
                    <div class="container-fluid">
                        <Modal
                            isOpen={this.state.cancelModalBool}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <h2>Konteyneri silmek istediğinize emin misiniz?</h2>
                            <h4 style={{ color: "red" }}>{this.state.errorMessage}</h4>
                            <h4 style={{ color: "green" }}>{this.state.deleteSuccessMessage}</h4>
                            <button class="btn btn-primary" type="button" style={{ marginRight: "0.3%", marginTop: "1%" }} onClick={async () => { this.deleteKonteyner() }}>Sil</button>
                            <button class="btn btn-primary" type="button" style={{ marginLeft: "0.3%", marginTop: "1%" }} onClick={async () => { await this.setState({ deleteSuccessMessage: "", errorMessage: "", deletedKonteynerId: "", cancelModalBool: false, }) }}>Kapat</button>

                        </Modal>
                        <div class="col-md-5 align-self-center">
                            <div style={{ display: 'flex' }}>
                                <Select 
                                    styles={{ input: styles => ({ ...styles, width: 50 }) }}
                                    value={{value: this.state.limit, label: this.state.limit}} 
                                    onChange={async (selectedOption) => {await this.setState({ limit: selectedOption.value })}}
                                    isMulti={false} options={limitOptions} placeholder="50"
                                />
                                <button class="btn btn-primary" onClick={() => this.setFilter(false)}>{this.state.limit} adet daha yükle</button>
                            </div>
                            <h4 class="text-themecolor">Belediye Filtrele</h4>
                            <select class="form-control-lg " onChange={async (e) => { await this.setState({ selectedMinistryId: e.target.value }) }}>
                                <option value="Tümü">Tümü</option>
                                {this.state.ministries.map(ministry => {
                                    return (
                                        <option value={ministry._id}>{ministry.district}</option>
                                    )
                                })}
                            </select>
                            <br />
                            <button class="btn btn-primary" type="button" style={{ marginTop: "4%" }} onClick={async () => { this.setFilter(true) }}>Filtreyi Uygula</button>
                            
                        </div>
                        <div class="row page-titles">
                            <div class="col-md-5 align-self-center">
                                <h4 class="text-themecolor">Konteynerler</h4>
                            </div>
                            <div class="col-md-7 align-self-center text-right">
                                <div class="d-flex justify-content-end align-items-center">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                        <li class="breadcrumb-item active">Konteynerler</li>
                                    </ol>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Konteynerler</h4>
                                        <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                                        <div className="table-responsive m-t-40">
                                            <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                                                <thead>
                                                    <tr>
                                                        <th>Konteyner Tipi</th>
                                                        <th>Konteyner Sayısı</th>
                                                        <th>Konteyner Tipleri</th>
                                                        <th>Resim</th>
                                                        <th>Mahalle</th>
                                                        <th>Sokak</th>
                                                        <th>Oluşturulma Tarihi</th>
                                                        <th>Enlem</th>
                                                        <th>Boylam</th>
                                                        <th>Sil</th>
                                                        <th>Bilgi Değiştir</th>
                                                    </tr>
                                                </thead>
                                                <tfoot>
                                                    <tr>
                                                        <th>Konteyner Tipi</th>
                                                        <th>Konteyner Sayısı</th>
                                                        <th>Konteyner Tipleri</th>
                                                        <th>Resim</th>
                                                        <th>Mahalle</th>
                                                        <th>Sokak</th>
                                                        <th>Oluşturulma Tarihi</th>
                                                        <th>Enlem</th>
                                                        <th>Boylam</th>
                                                        <th>Sil</th>
                                                        <th>Bilgi Değiştir</th>
                                                    </tr>
                                                </tfoot>
                                                <tbody>
                                                    {this.state.konteyners.map(rep => {

                                                        return (
                                                            <tr>
                                                                <td>{this.convertKonteynerTypeToString(rep.konteynerType)}</td>
                                                                <td>{rep.konteynerNumber}</td>
                                                                <td>{this.konteynerStringConverter(rep.type)}</td>
                                                                {rep.konteyner_image !== null
                                                                    ?
                                                                    <td><img style={{ width: 100, height: 100 }} src={"https://api.kollektapp.org/" + rep.konteyner_image.slice(10)} /></td>
                                                                    :
                                                                    <td>Resim yok</td>
                                                                }
                                                                <td>{rep.mahalle}</td>
                                                                <td>{rep.sokak}</td>
                                                                <td><span style={{ display: "none" }}>{dateConvertionForSort(new Date(Number(rep.createdAt)))}</span>{dateConvertion(new Date(Number(rep.createdAt)))}</td>
                                                                <td>{rep.latitude}</td>
                                                                <td>{rep.longitude}</td>
                                                                <td><span class="text-muted"><i class="fa fa-clock-o"></i> <button class="btn btn-primary" disabled={this.isDeleted(rep._id, true)} onClick={async () => { await this.setState({ deletedKonteynerId: rep._id, cancelModalBool: true }) }}>{this.isDeleted(rep._id, false)}</button></span></td>
                                                                <td><span class="text-muted"><button class="btn btn-primary" onClick={async () => { this.setKonteynerInfo(rep); await this.setState({ selectedInfo: rep, updateKonteynerInfo: true, }) }}>Bilgi Değiştir</button></span></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                <tbody>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div style={{ height: '90vh', width: '90%', padding: 20, overflow: 'hidden' }} className="card-body">
                                        {this.state.mapDataCame &&
                                            <Map styles={{ padding: '5%' }}
                                                onClick={this.onMapClicked}
                                                google={this.props.google}
                                                zoom={14}
                                                initialCenter={{
                                                    lat: this.state.mapLatLong.latitude,
                                                    lng: this.state.mapLatLong.longitude
                                                }}
                                            >

                                                {this.state.konteyners.map(kont =>

                                                    <Marker onClick={this.onMarkerClick}
                                                        position={{ lat: kont.latitude, lng: kont.longitude }}
                                                        name={kont} />

                                                )}
                                                <InfoWindow
                                                    marker={this.state.activeMarker}
                                                    visible={this.state.showingInfoWindow}>
                                                    <div>
                                                        <h5><b>Mah:</b> {this.state.mahalle}</h5>
                                                        <p><b>Sokak:</b> {this.state.sokak}</p>
                                                        <p><b>Tip:</b> {this.state.konteynerTipi}</p>
                                                        <p><b>Adet:</b> {this.state.konteynerAdet}</p>
                                                        <p><b>Durum:</b> {this.state.konteynerDurum}</p>
                                                        <p><b>Neler Atılabilir:</b> {this.state.konteynerAtilacaklar}</p>
                                                        {this.state.konteyner_image !== null
                                                            ?
                                                            <img style={{ width: 300, height: 300 }} src={"https://api.kollektapp.org/" + this.state.konteyner_image.slice(10)} />
                                                            :
                                                            <p>Resim yok</p>
                                                        }
                                                    </div>
                                                </InfoWindow>
                                            </Map>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )
        }
    }
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%'
    }
};

export default GoogleApiWrapper({
    apiKey: ("AIzaSyAdA0AjaFE0dcPMMqv9dRT06Pb1_A9xSS4")
})(Konteyners)