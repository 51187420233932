import React from 'react';
import Button from '../button/button';

const DeleteModalBody = ({
    errorMessage,
    successMessage,
    onDeleteClick,
    onCloseClick,
    headerText,
    deleteButtonDisabled
}) => (
    <>
        <h2>{headerText ? headerText : 'Bildirimi silmek istediğinize emin misiniz?'}</h2>
        <h4 style={{ color: "red" }}>{errorMessage}</h4>
        <h4 style={{ color: "green" }}>{successMessage}</h4>
        <Button 
            onClick={onDeleteClick}
            extraStyle={{ marginRight: "0.3%", marginTop: "1%" }}
            disabled={deleteButtonDisabled}
        >
            Sil
        </Button>
        <Button 
            onClick={onCloseClick}
            extraStyle={{ marginRight: "0.3%", marginTop: "1%" }}
        >
            Kapat
        </Button>
    </>
)

export default DeleteModalBody;