import React from 'react';
import { call } from '../services/api'

class DownloadExcel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isWasteDownloading: false,
      isUserDownloading: false,
      isKonteynerDownloading: false
    };
  }

  saveAsXlsxFile(base64Excel, type){
    var pre="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var element = document.createElement('a');
    element.setAttribute('href', pre + encodeURIComponent(base64Excel));
    element.setAttribute('download', type + '.xlsx');
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
    return pre+base64Excel;
}

  setStates = (type, flag) => {
    if (type === 'waste') this.setState({ isWasteDownloading: flag })
    else if (type === 'user') this.setState({ isUserDownloading: flag })
    else if (type === 'konteyner') this.setState({ isKonteynerDownloading: flag })
  }

  downloadExcel = type => {
    const requestBody = {
        query: `
            query{
                getExcelFile(type: "${type}")
            }
        `
    }
    this.setStates(type, true)
    call(requestBody)
        .then(res => {
            this.setStates(type, false)
            return this.saveAsXlsxFile(res.data.getExcelFile, type)
        })
        .catch(() => this.setStates(type, false))
  }

  render() {
    const { isKonteynerDownloading, isWasteDownloading, isUserDownloading } = this.state;
    return (
      <div class="page-wrapper">
        <div class="container-fluid">
          <div class="row page-titles">
            {!isWasteDownloading
              ?
              <button onClick={() => this.downloadExcel('waste')}class="btn-primary">Atık Raporlarını İndir</button>
              : 
              <button disabled={true} class="btn-primary">atık raporları indiriliyor</button>
            }
            {!isUserDownloading
              ?
              <button onClick={() => this.downloadExcel('user')} class="btn-primary">Kullanıcı Verilierini İndir</button>
              :
              <button disabled={true} class="btn-primary">kullanıcı verileri indiriliyor</button>
              
            }
            {!isKonteynerDownloading
              ?
              <button onClick={() => this.downloadExcel('konteyner')} class="btn-primary">Konteyner Verilerini İndir</button>
              :
              <button disabled={true} class="btn-primary">konteyner verileri indiriliyor</button>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadExcel;
