import React, { Component } from 'react'

const dataWait = () => {
    return (
        <div class="page-wrapper">
            <div class="container-fluid">
                <h1>Bekleyin...</h1>
            </div>
        </div>
    )
}

export default dataWait