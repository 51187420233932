import React,{Component} from 'react'
import {call} from '../../services/api'
import {cities} from '../../cityCodes'
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

class AddMinistry extends Component{

    constructor(props)
    {
        super(props);
        this.ministryName  = React.createRef();
        this.email = React.createRef();
        this.password  = React.createRef();
     

        this.state = {
            cityId: "",
            cityName: "",
            waitCreating: false,
            statusMessage:"",
            cols: [],
            rows: []
        }
    }


    createMinistry = async (event)=>{
    event.preventDefault()
    const ministryName = this.ministryName.current.value
    const email = this.email.current.value
    const password = this.password.current.value
   
    console.log(this.state)
    if(ministryName.trim().length === 0 || email.trim().length === 0 || password.trim().length === 0 || this.state.cityId === "" || this.state.cityId === 0 ||this.state.cityName.trim().length === 0)
    {
       
        return;
    }

    await this.setState({waitCreating: true})

    const name = ministryName+ " " + this.state.cityName  
    const requestBody = {
        query: `
            mutation{
                createMinistry(cityId:${this.state.cityId},cityName:"${this.state.cityName}",district:"${ministryName}",email:"${email}",password:"${password}", locationLatLong:[[${this.state.rows}]])
                {
                _id
                }
            }
        `}
    call(requestBody).
        then(async resData=>{
            if(resData.error)
            {
              await this.setState({waitCreating: false,statusMessage: "Belediye oluşturulamadı"})
            }else{
              console.log(resData.data.createMinistry._id)
              const userRequestBody = {
                query: `mutation{
                  createAdminGovUser(
                    userInput:{email:"${email}", password:"${password}",name:"${name}", registeredMinistry:"${resData.data.createMinistry._id}"}
                  ){
                    _id
                  }
                }`
              }

              call(userRequestBody).
              then(async resData=>{
                  if(resData.error)
                  {
                    await this.setState({waitCreating: false,statusMessage: "Belediye oluşturulamadı"})
                  }else{
                    this.props.history.replace('/addMinistry')
                    await this.setState({waitCreating: false,statusMessage: "Belediye oluşturuldu"})
                  }
            })
            .catch(async err => {console.log(err); await this.setState({waitCreating: false,statusMessage: "Belediye oluşturulamadı"})})
        }}).catch(async err=>{console.log(err); await this.setState({waitCreating: false,statusMessage: "Belediye oluşturulamadı"})})
    }

  tryLocations = () => {
    const requestBodyLocation = {
      query: `mutation{
        municipalityLocations(
          locations:[[${this.state.rows}]]
        ){
          _id
        }
      }`
    }

    call(requestBodyLocation)
    .then(res => {
      console.log(res)
    })
  }

  fileHandler = (event) => {
    let fileObj = event.target.files[0];

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err);            
      }
      else{
       
        this.setState({
          cols: resp.cols,
          rows: resp.rows
        });
      }
    });   
  }
    render(){
      if(this.state.waitCreating){
        return(
          <div class="page-wrapper">
          <div class="container-fluid">
          <h1>Bekleyin...</h1>
          </div>
          </div>
        )
      }
        return(
            <div className="page-wrapper">
            {/* ============================================================== */}
            {/* Container fluid  */}
            {/* ============================================================== */}
            <div className="container-fluid">
              {/* ============================================================== */}
              {/* Bread crumb and right sidebar toggle */}
              {/* ============================================================== */}
              <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                  <h4 className="text-themecolor">Belediye Oluşturma</h4>
                </div>
                
              </div>
              {/* ============================================================== */}
              {/* End Bread crumb and right sidebar toggle */}
              {/* ============================================================== */}
              {/* ============================================================== */}
              {/* Start Page Content */}
              {/* ============================================================== */}
              {/* row */}
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Belediye Bilgilerini Girin</h4>
                        
                      <form className="mt-4">
                        <div className="form-group">
                      
                        <label htmlFor="exampleInputEmail1">Şehir Seçin </label>
                        <br></br>
                        <select class="form-control-lg " onChange={async (e) => {await this.setState({ cityName: e.target.options[e.target.selectedIndex].text, cityId: Number(e.target.value)})}}>
                            <option value={0}>Şehir Seç</option>
                            {cities.map(city => {
                                return(
                                    <option value={city.code}>{city.name}</option>
                                )
                        })}
                        </select>
                        </div>
                        </form>
                      <form className="mt-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Belediye Adı</label>
                          <input type="text" className="form-control" id="etkinlik-adi" aria-describedby="emailHelp" ref={this.ministryName} placeholder="Belediye adını yazın" required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Belediye Admin Hesap Email</label>
                          <input type="email" className="form-control" id="etkinlik-aciklamasi" ref={this.email} placeholder="Belediye'ye verilecek hesabın email adresini yazın" required/>
                        </div>
                   
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Şifre</label>
                          <input type="password" className="form-control" id="etkinlik-mekani" ref={this.password} placeholder="Hesap şifresi" required/>
                        </div>
                        {this.state.statusMessage === "Belediye oluşturuldu" && 
                        <h3 style={{color:"green"}}>{this.state.statusMessage}</h3>}
                        {this.state.statusMessage === "Belediye oluşturulamadı" && 
                        <h3 style={{color:"red"}}>{this.state.statusMessage}</h3>}
                        <button type="submit" onClick={this.createMinistry} className="btn btn-primary">Belediye Oluştur</button>
                      </form>
                      {/*<button onClick={this.tryLocations}>Dene</button>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default AddMinistry