import React,{Component} from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import {dateConvertion} from '../helpers/dateHelpers'

import {call} from '../../services/api'

class CommunicationInfos extends Component{

    constructor(props){
        super(props)
        
        this.kurumName = ""
        this.email = ""
        this.phone = ""
        this.address = ""
        this.latitude = ""
        this.longitude = ""
        
        this.state = {
            infos: [],
            dataCame: false,
            showAll: true,
            selectedInfo: null,
            resultMessage:""
        }
    }


    

    componentWillMount(){
        const requestBody = {
            query: `
                query{
                    communicationInfos
                    {
                        _id
                        kurumName
                        email
                        phone
                        address
                        latitude
                        longitude
                    }
                }
            `
        }
        

        
        call(requestBody).then(async resData=>{
                if(resData.errors) 
                {
                    console.log("unauthenticated")
                }
                console.log(resData)
             
               
                await this.setState({infos: resData.data.communicationInfos, dataCame:true})
                
            }).catch(err=>{console.log(err)})

        
    }

    componentDidUpdate() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `$('#example23').DataTable({
                            dom: 'Bfrtip',
                        buttons: [
                            'copy', 'csv', 'excel', 'pdf', 'print'
                        ]
                    });
                    $('.buttons-copy, .buttons-csv, .buttons-print, .buttons-pdf, .buttons-excel').addClass('btn btn-primary mr-1');
                `;
        if(this.state.dataCame)
        {
            document.body.appendChild(s);
        }
    }

    showStatus(stat){
        if(stat === "bekliyor"){
            return "beklemede"
        }
    }

    async showChangeScreen(info){
        this.kurumName = info.kurumName
        this.email = info.email
        this.phone = info.phone
        this.address = info.address
        this.latitude = info.latitude
        this.longitude = info.longitude

        await this.setState({selectedInfo:info, showAll: false})
    }

    async updateInfo(){
        const kurumName = this.kurumName
        const email = this.email
        const phone = this.phone
        const address = this.address
        const latitude = this.latitude
        const longitude = this.longitude

        const requestBody = {
            query: `
                mutation{
                    updateCommunicationInfo(_id:"${this.state.selectedInfo._id}", kurumName:"${kurumName}",email:"${email}",phone:"${phone}",address:"${address}",latitude:${latitude},longitude:${longitude})
                    {
                    _id
                    }
                }
            `}
        

        call(requestBody).
        then(resData=>{
            if(resData.errors){
                this.setState({resultMessage:"Kurum güncellenemedi"})
            }else{
                this.setState({resultMessage:"Kurum başarıyla güncellendi"})
            }
        })
        .catch(err=>{console.log(err); this.setState({resultMessage:"Kurum güncellenemedi"})})
    }

    render(){
        if(!this.state.dataCame){
            return(
                <div class="page-wrapper">
                <div class="container-fluid">
                <h1>Bekleyin...</h1>
                </div>
                </div>
            )
        }
        else if(this.state.showAll){
            return (

                <div class="page-wrapper">
                    <div class="container-fluid">
        
                    <div class="row page-titles">
                            <div class="col-md-5 align-self-center">
                                <h4 class="text-themecolor">Kurum Bilgileri</h4>
                            </div>
                            <div class="col-md-7 align-self-center text-right">
                                <div class="d-flex justify-content-end align-items-center">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                        <li class="breadcrumb-item active">Kurum Bilgileri</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
        
                        <div className="row">
                            <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                <h4 className="card-title">Kurum Bilgileri</h4>
                                <h6 className="card-subtitle">Aşağıdaki opsiyonlardan birini kullnarak çıktı alabilirsiniz.</h6>
                                <div className="table-responsive m-t-40">
                                    <table id="example23" className="display nowrap table table-hover table-striped table-bordered" cellSpacing={0} width="100%">
                                    <thead>
                                        <tr>
                                        <th>Kurum Adı</th>
                                        <th>Email</th>
                                        <th>Telefon No</th>
                                        <th>Adres</th>
                                        <th>Enlem</th>
                                        <th>Boylam</th>
                                        <th>İşlem</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                        <th>Kurum Adı</th>
                                        <th>Email</th>
                                        <th>Telefon No</th>
                                        <th>Adres</th>
                                        <th>Enlem</th>
                                        <th>Boylam</th>
                                        <th>İşlem</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {this.state.infos.map(info => {
                                            return(
                                                <tr>
                                                <td>{info.kurumName}</td>
                                                <td>{info.email}</td>
                                                <td>{info.phone}</td>
                                                <td>{info.address}</td>
                                                <td>{info.latitude}</td>
                                                <td>{info.longitude}</td>
                                                <td><span class="text-muted"><button class="btn btn-primary" onClick={async () => {await this.showChangeScreen(info)}}>Değiştir</button></span></td>
                                                </tr> 
                                              )              
                                        })}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
        }else if(!this.state.showAll){
            return(
                <div className="page-wrapper">  
                    <div className="container-fluid">          
                    <div className="row page-titles">
                        <div className="col-md-5 align-self-center">
                        <h4 className="text-themecolor">Kurum Bilgisi Güncelleme</h4>
                        </div>                
                    </div>            
                    <div className="row">
                        <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                            <h4 className="card-title">Kurum İletişim Bilgilerini Güncelleyin</h4>
                            
                            <form className="mt-4">
                                <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Kurum Adı</label>
                                <input type="text" className="form-control"
                                 onChange={(e) => {e.target.value.trim().length === 0 ? this.kurumName = this.state.selectedInfo.kurumName : this.kurumName = e.target.value}}
                                 placeholder={this.kurumName}
                                  />
                                </div>
                                <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Kurum Email Adresi</label>
                                <input type="text" className="form-control" 
                                onChange={(e) => {e.target.value.trim().length === 0 ? this.email = this.state.selectedInfo.email : this.email = e.target.value}}
                                placeholder={this.email} />
                                </div>
                                
                                <div className="form-group">
                                    <label>Telefon Numarasını</label>
                                    <input type="text" class="form-control"
                                    onChange={(e) => {e.target.value.trim().length === 0 ? this.phone = this.state.selectedInfo.phone : this.phone = e.target.value}}
                                    placeholder={this.phone} />                
                                </div>
                                <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Kurum Adresi</label>
                                <input type="text" className="form-control" 
                                onChange={(e) => {e.target.value.trim().length === 0 ? this.address = this.state.selectedInfo.address : this.address = e.target.value}}
                                placeholder={this.address} />
                                </div>
                                <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Enlem</label>
                                <input type="number" className="form-control"
                                onChange={(e) => {e.target.value.trim().length === 0 ? this.latitude = this.state.selectedInfo.latitude : this.latitude = e.target.value}}                               
                                placeholder={this.latitude} />
                                </div>
                                <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Boylam</label>
                                <input type="number" className="form-control" 
                                onChange={(e) => {e.target.value.trim().length === 0 ? this.longitude = this.state.selectedInfo.longitude : this.longitude = e.target.value}}                                                               
                                placeholder={this.longitude} />
                                </div>
                                <p style={this.state.resultMessage === "Kurum güncellenemedi" ? {color:"red"} : {color:"green"}}>{this.state.resultMessage}</p>
                            </form>
                            <button onClick={() =>{this.updateInfo()}} className="btn btn-primary">Kurum Bilgisi Güncelle</button><br></br>
                            <button onClick={() =>{this.setState({showAll:true})}} className="btn btn-primary">Geri</button>

                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
          )
        }
        
    }
}

export default CommunicationInfos