import React,{Component, } from 'react'

import {call} from '../services/api'

class MailToAll extends Component {
    constructor(props){
        super(props)
        this.mailBody = ""
        this.mailBaslik = ""
        this.state = {
            mailSucces:""
        }
    }
    
    sendMail(){
        //console.log(this.mailBody)
        //console.log(this.mailBaslik)
        const requestBody = {
            query: `mutation{
                sendMailToAllMinistries(body:"""${this.mailBody}""" ,subject:"${this.mailBaslik}")
            }`
        }

        call(requestBody)
        .then(async resData => {
            if(resData.errors){
                await this.setState({mailSucces: "işlem başarısız."})
            }else {
            this.setState({mailSucces: "Mail başarıyla iletildi."})
        }
        })
        .catch(async err => {
            await this.setState({mailSucces: "İşlem başarısız."})
        })
    }

    render(){
    
        return (
            <div class="page-wrapper">
                <div class="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h1>Toplu Mail Gönder</h1>
                                    <form className="mt-4"> 
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Mail Başlığı</label>
                                        <input type="text" className="form-control" onChange={(e) => {this.mailBaslik = e.target.value}} required/>
                                    </div>
                                        <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Mail İçeriği</label>
                                        <textarea rows="20" className="col-12 textarea_editor form-control" onChange={(e) => {this.mailBody = e.target.value}}>
                                        </textarea>                                            
                                        </div>
                                        <p style={{color:"red"}}>{this.state.errMessage}</p>
                                    </form>
                                    <p style={this.state.mailSucces === "İşlem başarısız." ? {color:"red"} : {color:"green"}}>{this.state.mailSucces}</p>
                                    <button onClick={() => this.sendMail()} className="btn btn-primary">Maili Gönder</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MailToAll