import React from 'react'
import { Scatter } from 'react-chartjs-2'


export const getScatterChart = (datasets) => {
    return (
        <div class="col-12" style={{ height: 200, position: "relative" }}>
            <Scatter
                options={
                    {
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                            xAxes: [{
                                position: "bottom",
                                type: 'time',
                                display: true,
                                time: {
                                    displayFormats: {
                                        'millisecond': 'MMM YYYY',
                                        'second': 'MMM YYYY',
                                        'minute': 'MMM YYYY',
                                        'hour': 'MMM YYYY',
                                        'day': 'MMM YYYY',
                                        'week': 'MMM YYYY',
                                        'month': 'MMM YYYY',
                                        'quarter': 'MMM YYYY',
                                        'year': 'MMM YYYY'
                                    }
                                },
                            }]
                        }
                    }
                }
                data={{
                    datasets: datasets
                }}
            />
        </div>
    )
}