import React, { Component } from 'react'
import { call } from '../../services/api'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import TableComponent from '../components/tableScreen/tableComponent'
import { createDataTable } from '../components/tableScreen/componentHelpers/createDataTable'
import DateOption from '../components/options/dateOptions'
import PageWrapper from '../components/pageWrapper'
import DataWait from '../components/dataWait'
import { getScatterChart } from '../components/scatterChart'

const filterByOption = [
    { value: "point", label: "Puan alımına göre" },
    { value: "register", label: "Kayıt olma tarihine göre" }
]

class AdvancedUserQuery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showWasteReports: false,
            dataCame: true,
            selectedSGun: { value: 0, label: "Tümü" },
            selectedSYil: { value: 0, label: "Tümü" },
            selectedSAy: { value: 0, label: "Tümü" },
            selectedEGun: { value: 0, label: "Tümü" },
            selectedEYil: { value: 0, label: "Tümü" },
            selectedEAy: { value: 0, label: "Tümü" },
            filterBy: { value: "point", label: "Puan alımına göre" },
            dataSets: []
        }
    }

    async setTimeState(varName, selectedOption) {
        await this.setState({ [varName]: selectedOption })
    }

    async setFilter() {
        const advancedUserRequest = {
            query: `query {
                advancedUserQuery(
                    filterBy:"${this.state.filterBy.value}"
                    sMonth:${this.state.selectedSAy.value}, 
                    sDay:${this.state.selectedSGun.value}, 
                    sYear:${this.state.selectedSYil.value}, 
                    eMonth:${this.state.selectedEAy.value}, 
                    eDay:${this.state.selectedEGun.value}, 
                    eYear:${this.state.selectedEYil.value}
                    )
                    {
                        _id
                        email
                        name
                        createdWasteReports{_id}
                    }
            }`
        }
        await this.setState({ dataCame: false })
        call(advancedUserRequest).then(async resData => {
            if (resData.errors) {
                console.log(resData.errors)
                return
            }

            //create datasets for each user
            let dataSets = []
            for(let i = 0; i < resData.data.advancedUserQuery.length; i++){
                let dataSet = []
                let ids = []
                if (resData.data.advancedUserQuery[i].createdWasteReports.length !== 0) {
                    await resData.data.advancedUserQuery[i].createdWasteReports.map(id => ids.push(id._id))
                    dataSet = await this.createChartData(ids)
                    dataSets.push(dataSet)
                }
            }

            await this.setState({ dataSets: dataSets })
            await this.setState({ users: resData.data.advancedUserQuery, dataCame: true, showWasteReports: true })
            createDataTable()

        }).catch(async err => {
            console.log(err); await this.setState({ dataCame: true }); createDataTable()
        })
    }

    async createChartData(wasteIds) {
        let timestamp
        let timest
        let dataModel = []
        await wasteIds.map(id => {
            timestamp = id.substring(0, 8)
            timest = new Date(parseInt(timestamp, 16) * 1000)
            dataModel.push({ x: new Date(timest.getFullYear(), timest.getMonth(), timest.getDate()), y: 2 })
        })
        dataModel.push({ x: new Date(timest.getFullYear(), timest.getMonth(), timest.getDate()), y: 1 })

        return dataModel
    }

    getFilterOptions() {
        return (
            <div class="row page-titles">
                <div class="col-md-2">
                    <label>Puan alımına göre / Yeni kayıta göre</label>
                    <Select value={this.state.filterBy} onChange={(selectedOption) => { this.setState({ filterBy: selectedOption }) }} isMulti={false} options={filterByOption} />
                </div>

                <div class="col-md-2 align-self-center">
                    <p style={{ fontWeight: "bold", fontSize: 16 }}>Başlangıç Tarihi</p>
                    <hr></hr>
                    <DateOption
                        gun={"selectedSGun"}
                        ay={"selectedSAy"}
                        yil={"selectedSYil"}
                        selectedGun={this.state.selectedSGun}
                        selectedAy={this.state.selectedSAy}
                        selectedYil={this.state.selectedSYil}
                        setFunction={this.setTimeState.bind(this)}
                    />
                </div>
                <div class="col-md-2 align-self-center">
                    <p style={{ fontWeight: "bold", fontSize: 16 }}>Bitiş Tarihi</p>
                    <hr></hr>
                    <DateOption
                        gun={"selectedEGun"}
                        ay={"selectedEAy"}
                        yil={"selectedEYil"}
                        selectedGun={this.state.selectedEGun}
                        selectedAy={this.state.selectedEAy}
                        selectedYil={this.state.selectedEYil}
                        setFunction={this.setTimeState.bind(this)}
                    />
                </div>
                <button class="btn btn-primary" type="button" style={{ marginTop: "4%" }} onClick={async () => { this.setFilter() }}>Filtreyi Uygula</button>
            </div>
        )
    }

    render() {
        if (!this.state.dataCame) {
            return (
                <DataWait />
            )
        }
        else if (!this.state.showWasteReports) {
            return (
                <PageWrapper>
                    {this.getFilterOptions()}
                </PageWrapper>
            )
        }
        return (
            <PageWrapper>
                {this.getFilterOptions()}
                <TableComponent
                    tableHeadersArray={
                        [
                            "Email",
                            "İsim",
                            "Yapılan Bildirim",
                            "Bildirim Grafiği",
                            "Görüntüle",
                        ]
                    }
                    tableContentArray={
                        this.state.users.map((user, index) => {
                            return (
                                [
                                    user.email,
                                    user.name,
                                    user.createdWasteReports.length,
                                    getScatterChart([{
                                        label: 'Tarihe göre bildirimler',
                                        data: this.state.dataSets[index],
                                        backgroundColor: "black"
                                    }]),
                                    <><span class="text-muted"><i class="fa fa-clock-o"></i> <Link target="_blank" to={{ pathname: `/userReportDetailPage?_id=${user._id}` }}>Görüntüle</Link></span></>
                                ]
                            )
                        })
                    }
                    pageHeader={"Gelişmiş Kullanıcı Arama"}
                />
            </PageWrapper>
        )
    }
}

export default AdvancedUserQuery