import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import AuthContext from './context/auth-context';

//Pages 
import WasteReportDetailPage from './pages/WasteReportDetailPage'
import AuthPage from './pages/Auth';
import MainPage from './pages/MainPage'
import Konteyners from './pages/Konteyners'
import CreateEvent from './pages/CreateEvent'
//Waste Reports Pages
import PastWasteReports from './pages/wasteReports/pastWasteReports'
import AllWasteReports from './pages/wasteReports/allWasteReports'
import ActiveWasteReports from './pages/wasteReports/activeWasteReports'
import CanceledWasteReports from './pages/wasteReports/canceledWasteReports'
import IsSelfWasteReports from './pages/wasteReports/isSelfWasteReports'
import NotHandledWasteReports from './pages/wasteReports/notHandledWasteReports'
import LogoutPage from './pages/LogOut'
import InstantMuchReports from './pages/wasteReports/instantMuchReports'
import AdvancedReportQuery from './pages/wasteReports/advancedReportQuery'
//Events Pages
import ActiveEvents from './pages/events/activeEvents'
import PastEvents from './pages/events/pastEvents'
import AllEvents from './pages/events/allEvents'
import PdfTry from './pages/pdfTry'
//Reporting Pages
import WasteReporting from './pages/reports/wasteReporting'
import UserReporting from './pages/reports/userReporting'
import WasteReportPlace from './pages/reports/wasteReportPlace'
import MinistryReporting from './pages/reports/ministryReporting'
import AgeGenderReporting from './pages/reports/byAgeGenderReporting'
import GeneralMinistryReport from './pages/reports/generalReportMinistry'
//Ministry Pages
import AddMinistry from './pages/ministry/addMinistry'
import CreateMinistryAccount from './pages/accounts/createMinistryAccount'
//Acount
import CreateDKMAccount from './pages/accounts/createDKMAccount'
import AllAccounts from './pages/accounts/allAccounts'
// Components 
import { Provider } from 'react-redux'
import {persistor, store} from './store/index'
import { PersistGate } from 'redux-persist/integration/react'
//UserOpertaions
import UserBanning from './pages/userOperations/userBanning'
import UserReportDetailPage from './pages/userOperations/userReportDetail'
import AdvancedUserQuery from './pages/userOperations/advancedUserQuery'
//CommunicationInfo
import AddCommunicationInfo from './pages/communication-announcement/addCommunication'
import CommunicationInfos from './pages/communication-announcement/communicationInfos'
//Announcements
import AddAnnouncement from './pages/communication-announcement/addAnnouncement'
import Announcements from './pages/communication-announcement/announcements'
//Konteyner
import AddKonteyner from './pages/addKonteyner'
import AddKonteynerExcel from './pages/addKonteynerExcel'

import DownloadExcel from './pages/downloadExcel';

import MailToAll from './pages/mailToAll'

import Header from './components/header'
import Sidebar from './components/sidebar'
import Footer from './components/footer';

import RequireAuth from './components/RequireAuth';

//Municipality Adding
import MunicipalityEnterExcel from './pages/municipalityEnteringExcel'

import { setToken, setCurrentUser, addError, setMunicipality } from './store/actions'

import ComponentTest from './pages/components/wasteReportComponent'

if (localStorage.jwtToken) {
  setToken(localStorage.jwtToken)
  try {
    store.dispatch(setCurrentUser(localStorage.jwtToken))
  } catch (error) {
    store.dispatch(setCurrentUser({}))
    store.dispatch(addError(error))
  }
}

class App extends Component {

  state = {
    token: null,
    _id: null,
    redirect: false
  }


  render() {
    console.log = function () {};
    console.log(this.state.token)
    return (
      <Provider store={store}>
          <BrowserRouter>
            <AuthContext.Provider value={{
              token: this.state.token,
              _id: this.state._id,
              login: this.login,
              logout: this.logout
            }}>
              <Header />
              <Sidebar />
              <Switch>
                <Route path="/" exact component={RequireAuth(MainPage)}><Redirect to="/index"></Redirect></Route>
                <Route path="/auth" exact component={AuthPage} />
                <Route path="/index" exact component={RequireAuth(MainPage)} />
                <Route path="/createEvent" exact component={CreateEvent} />
                <Route path="/konteyner" exact component={Konteyners} />
                <Route path="/wasteReportDetail" exact component={WasteReportDetailPage} />
                <Route path="/logout" exact component={LogoutPage} />
                <Route path="/activeWasteReports" component={ActiveWasteReports} />
                <Route path="/pastWasteReports" component={PastWasteReports} />
                <Route path="/allWasteReports" component={AllWasteReports} />
                <Route path="/canceledWasteReports" component={CanceledWasteReports} />
                <Route path="/activeEvents" component={ActiveEvents} />
                <Route path="/pastEvents" component={PastEvents} />
                <Route path="/allEvents" component={AllEvents} />
                <Route path="/pdfTry" component={PdfTry} />
                <Route path="/wasteReporting" component={WasteReporting} />
                <Route path="/userReporting" component={UserReporting} />
                <Route path="/addMinistry" component={AddMinistry} />
                <Route path="/createMinistryAccount" component={CreateMinistryAccount} />
                <Route path="/createDKMAccount" component={CreateDKMAccount} />
                <Route path="/isSelfWasteReports" component={IsSelfWasteReports} />
                <Route path="/notHandledWasteReports" component={NotHandledWasteReports} />
                <Route path="/userBanning" component={UserBanning} />
                <Route path="/addKonteyner" component={AddKonteyner} />
                <Route path="/wasteReportPlace" component={WasteReportPlace} />
                <Route path="/addKonteynerExcel" component={AddKonteynerExcel} />
                <Route path="/instantMuchReports" component={InstantMuchReports} />
                <Route path="/addCommunicationInfo" component={AddCommunicationInfo} />
                <Route path="/communicationInfos" component={CommunicationInfos} />
                <Route path="/addAnnouncement" component={AddAnnouncement} />
                <Route path="/announcements" component={Announcements} />
                <Route path="/ministryReporting" component={MinistryReporting} />
                <Route path="/generalMinistryReporting" component={GeneralMinistryReport} />
                <Route path="/userReportDetailPage" exact component={UserReportDetailPage} />
                <Route path="/advancedUserQuery" component={AdvancedUserQuery} />
                <Route path="/ageGenderReporting" component={AgeGenderReporting} />
                <Route path="/mailToAll" component={MailToAll} />
                <Route path="/allAccounts" component={AllAccounts} />
                <Route path="/municipalityEnterExcel" component={MunicipalityEnterExcel} />
                <Route path="/advancedReportQuery" component={AdvancedReportQuery} />
                <Route path="/test" component={ComponentTest} />
                <Route path="/downloadExcel" component={DownloadExcel} />
              </Switch>
              <Footer />
            </AuthContext.Provider>
          </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
