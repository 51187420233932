import React, { Component } from 'react'
import { connect } from 'react-redux';
import {getDkmPastWasteReports} from './apiCalls.js/apiEndpoints'
import WasteReportTableComponent from "../components/wasteReportComponent"

class PastWasteReports extends Component {
    render() {
        return (
            <WasteReportTableComponent
                reportApiCall={getDkmPastWasteReports}
                pageHeader="Geçmiş Atık Bildirimleri"
                contentType="municipality"
            />
        )
    }
}
          
export default connect((store)=>({auth:store.auth}))(PastWasteReports)
