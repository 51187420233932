import React,{Component} from 'react'

import {call} from '../../services/api'
import {cities} from '../../cityCodes'

class CreateMinistryAccount extends Component{

    constructor(props)
    {
        super(props);
        this.name  = React.createRef();
        this.email = React.createRef();
        this.password  = React.createRef();
     

        this.state = {
            cityId: "",
            cityName: "",
            waitCreating: false,
            statusMessage:"",
            ministries:[],
            selectedMinistryId:"",
            dataCame: false,
            userType: "GOV_USER"
        }
    }


    componentWillMount(){
        const ministryRequest = {
            query: `query {
                getMinistry{
                    _id
                    district
                }
            }`
        }

        call(ministryRequest).then(resData=>{
            if(resData.errors) 
            {
                console.log("unauthenticated")
            }
            console.log(resData)
           
            
            this.setState({ministries: resData.data.getMinistry, selectedMinistryId:resData.data.getMinistry[0]._id, dataCame:true})
            
        }).catch(err=>{console.log(err)})
    }

    createAccount = async (event)=>{
    event.preventDefault()
    const email = this.email.current.value
    const password = this.password.current.value
    const name = this.name.current.value
   
   
    if( email.trim().length === 0 || password.trim().length === 0)
    {
        return;
    }

    await this.setState({waitCreating: true})

        const userRequestBody = {
        query: `mutation{
            createGovDkmUser(
            email:"${email}", password:"${password}", name:"${name}",registeredMinistry:"${this.state.selectedMinistryId}", userType:"${this.state.userType}"
            ){
            _id
            }
        }`
        }

        call(userRequestBody).
        then(async resData=>{
            if(resData.errors)
            {
            await this.setState({waitCreating: false,statusMessage: "Hesap oluşturulamadı"})
            }else{
            
            await this.setState({waitCreating: false,statusMessage: "Hesap oluşturuldu"})
            }
    })
    .catch(async err => {console.log(err); await this.setState({waitCreating: false,statusMessage: "Hesap oluşturulamadı"})})

  }

    render(){
        if(!this.state.dataCame){
            return(
                <div class="page-wrapper">
                <div class="container-fluid">
                <h1>Bekleyin...</h1>
                </div>
                </div>
            )
        }
        if(this.state.waitCreating){
            return(
            <div class="page-wrapper">
            <div class="container-fluid">
            <h1>Bekleyin...</h1>
            </div>
            </div>
            )
        }
        return(
            <div className="page-wrapper">
            {/* ============================================================== */}
            {/* Container fluid  */}
            {/* ============================================================== */}
            <div className="container-fluid">
              {/* ============================================================== */}
              {/* Bread crumb and right sidebar toggle */}
              {/* ============================================================== */}
              <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                  <h4 className="text-themecolor">Belediye Hesabı Oluşturma</h4>
                </div>
                
              </div>
              {/* ============================================================== */}
              {/* End Bread crumb and right sidebar toggle */}
              {/* ============================================================== */}
              {/* ============================================================== */}
              {/* Start Page Content */}
              {/* ============================================================== */}
              {/* row */}
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Lütfen Gerekli Bilgilerini Girin</h4>
                        
                   
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Hesabın Oluşturulacağı Belediyeyi Seç</h4>
                        <select class="form-control-lg " onChange={async (e) => {await this.setState({ selectedMinistryId: e.target.value })}}>
                            {this.state.ministries.map(ministry => {
                                return(
                                    <option value={ministry._id}>{ministry.district}</option>
                                )
                            })}
                        </select>
                        <br/>
                    </div>   
                    <div class="col-md-5 align-self-center">
                        <h4 class="text-themecolor">Kullanıcı Tipini Seç</h4>
                        <select class="form-control-lg " onChange={async (e) => {await this.setState({ userType: e.target.value })}}>
                                <option value={"GOV_USER"}>GOV_USER</option>
                                <option value={"GOV_SUPER"}>GOV_SUPER</option>
                                )}
                        </select>
                        <br/>
                    </div>     
                      <form className="mt-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Kullanıcı Email</label>
                          <input type="text" className="form-control" id="etkinlik-adi" aria-describedby="emailHelp" ref={this.email} placeholder="Kullanılacak email'i yazın" required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Kullanıcı Adı Soyadı</label>
                          <input type="text" className="form-control" id="etkinlik-adi" aria-describedby="emailHelp" ref={this.name} placeholder="Kullanılacak email'i yazın" required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Şifre</label>
                          <input type="password" className="form-control" id="etkinlik-mekani" ref={this.password} placeholder="Hesap şifresi" required/>
                        </div>
                        {this.state.statusMessage === "Hesap oluşturuldu" && 
                        <h3 style={{color:"green"}}>{this.state.statusMessage}</h3>}
                        {this.state.statusMessage === "Hesap oluşturulamadı" && 
                        <h3 style={{color:"red"}}>{this.state.statusMessage}</h3>}
                        <button type="submit" onClick={this.createAccount} className="btn btn-primary">Belediye Hesabı Oluştur</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default CreateMinistryAccount