import React,{Component} from 'react'

import {call} from '../../services/api'
import ImageUploader from 'react-images-upload';
import { graphql } from 'react-apollo';
const gql = require('graphql-tag')
/* eslint-disable import/first */

import{authUser,logout} from '../../store/actions'


import {connect} from 'react-redux'
import {compose} from 'redux'
import {createUploadLink} from 'apollo-upload-client'
import {ApolloClient} from 'apollo-client'
import {InMemoryCache} from 'apollo-cache-inmemory'
import Dropzone from 'react-dropzone'




class AddAnnouncement extends Component{

    constructor(props)
    {
        super(props);
        this.İllustration = React.createRef();
        this.title = React.createRef();
        this.titleTr = React.createRef();
        this.titleRu = React.createRef();
        this.titleEn = React.createRef();
        this.onDrop = this.onDrop.bind(this);
        this.state = {
          resultMessage:"",
          pictures: []
        }
    }


 createKurum = (event)=>{
    event.preventDefault()
  
    //console.log(client)

    const title = this.title.current.value
    const titleTr = this.titleTr.current.value
    const titleRu = this.titleRu.current.value
    const titleEn = this.titleEn.current.value


    this.props.mutate({
      context:{ 
        headers: {
        authorization: `Bearer ${this.props.auth.user}`
      }
    },
     variables: { file:this.state.pictures[0],title,titleTr,titleEn,titleRu } 
    }).then((res)=>{console.log(res);this.setState({resultMessage:"Duyuru Başarılı bir şekilde eklendi."})})



  }
   
  randomText = (length) => {
    var text = "";
    var possible = "0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  async onDrop(picture) {
    await this.setState({
        pictures: this.state.pictures.concat(picture),
    });

    console.log(this.state.pictures)

  }

  render(){
        return(
          <div className="page-wrapper">  
            <div className="container-fluid">          
              <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                  <h4 className="text-themecolor">Duyuru Oluşturma</h4>
                </div>                
              </div>            
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Duyuru Bilgilerini Girin</h4>
                      

                      <form className="mt-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Yüklenecek Resmi Seçin</label><br></br>

                        <Dropzone onDrop={async (acceptedFiles) => { await this.setState({pictures:acceptedFiles})}}>
                          {({getRootProps, getInputProps}) => (
                            <section>
                              <div style={{height:100,verticalAlign:'center',textAlign:'center',border:2,borderColor:'black'}} {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Dosya eklemek için tıklayın yada sürükleyip bırakın.</p>
                              </div>
                            </section>
                          )}
                        </Dropzone> 
                        {this.state.pictures.length !== 0 && <p>{this.state.pictures[0].name} seçildi</p>}
  
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Genel Başlık</label>
                          <input type="text" className="form-control" ref={this.title} placeholder="Türkçe başlığı girin" required/>
                        </div>
                        
                        <div className="form-group">
                            <label>Türkçe Başlık</label>
                            <input type="text" class="form-control" ref={this.titleTr} placeholder="Türkçe başlığı girin" required/>                
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Rusça Başlık</label>
                          <input type="text" className="form-control" ref={this.titleRu} placeholder="Rusça başlığı girin" required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">İngilizce Başlık</label>
                          <input type="text" className="form-control" ref={this.titleEn} placeholder="İngilizce başlığı girin" required/>
                        </div>
                     
                        <p style={this.state.resultMessage === "Kurum eklenemedi" ? {color:"red"} : {color:"green"}}>{this.state.resultMessage}</p>
                        <button type="submit" onClick={this.createKurum} className="btn btn-primary">Ekle</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

const reduxWrapper = connect((store)=>({auth:store.auth}),{authUser,logout})

const uploadImage = gql`mutation($file: Upload!,$title:String,$titleTr:String,$titleEn:String,$titleRu:String) {createCarousel(file: $file, title:$title, titleTr:$titleTr, titleEn:$titleEn, titleRu:$titleRu) {_id}}
`
  
const grapqlWrapper = graphql(uploadImage)

export default compose(reduxWrapper,grapqlWrapper)(AddAnnouncement)