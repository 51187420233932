import React,{Component} from 'react'
import AuthContext from '../../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import {dateConvertion} from '../helpers/dateHelpers'
import { call } from '../../services/api';


class AgeGenderReporting extends Component{

    

    constructor(props) {
        super(props);
        this.state = {
          
            dataCame: false,
            byAge: [],
            byGender: []
        }
        
    
      }

    async componentWillMount(){
       
       const requestBody ={
           query:`query{
            getWasteReportByUserDetail{
                wasteReportByAge{
                    _id
                    count
                }
                wasteReportByGender{
                    _id
                    count
                }
                }
            }` 
       }
        call(requestBody)
        .then(async res => {
            if(!res.errors)
            await this.setState({byAge: res.data.getWasteReportByUserDetail.wasteReportByAge, byGender:res.data.getWasteReportByUserDetail.wasteReportByGender, dataCame:true})
            console.log(res)
        })
        .catch(err => console.log(err))
    }
 

    render(){
        if(!this.state.dataCame){
            return(
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Bekleyin...</h1>
                    </div>
                </div>
            )
        }else{
            return (

                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Yaşa Göre</h1>
                        <div class="row">
                            {this.state.byAge.map(info => {
                                return(
                                    <div class="col-lg-4 col-md-6">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row p-t-10 p-b-10">
                                                <div class="col p-r-0">
                                                    <h1 class="font-light">{info.count} rapor</h1>
                                                    <h6 class="text-muted">{info._id} bireyler tarafından oluşturulmuş</h6></div>      
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            })}
                        </div>
                        <h1>Cinsiyete Göre</h1>
                        <div class="row">
                        {this.state.byGender.map(info => {
                                return(
                                    <div class="col-lg-4 col-md-6">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row p-t-10 p-b-10">
                                                <div class="col p-r-0">
                                                    <h1 class="font-light">{info.count} rapor</h1>
                                                    <h6 class="text-muted">{info._id === "2" 
                                                    ?
                                                    "Erkek" 
                                                    : info._id === "1" 
                                                    ? 
                                                    "Kadın"
                                                    :
                                                    info._id === "3"
                                                    ?
                                                    "Diğer"
                                                    :
                                                    info._id === null
                                                    ?
                                                    "Cinsiyet bilgisi girmemiş"
                                                    :
                                                    "" } kullanıcılar tarafından oluşturulmuş</h6></div>      
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            })}
                        </div>
                   </div>
                </div>
            )
        }
    }
}

export default AgeGenderReporting