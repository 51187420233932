import React, { Component } from 'react'
import { call } from '../../services/api'
import { dateConvertion, dateConvertionForSort, notificationExplanation } from '../helpers/dateHelpers'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import { ShowKonteynerNameAccordingToType } from '../helpers/markerHelpers'
import TableComponent from '../components/tableScreen/tableComponent'
import { getWasteStatus } from '../components/tableScreen/componentHelpers/generalHelpers'
import { createDataTable } from '../components/tableScreen/componentHelpers/createDataTable'
import { nelerAtilirOptions, bildirimStatusOptions, atikToplanmaDurumuOptions, isSelfOption } from './advancedQueryHelpers/adQueryoptionData'
import DateOption from '../components/options/dateOptions'

class AdvancedReportQuery extends Component {

    constructor(props) {
        super(props)
        this.nelerAtilabilir = React.createRef();
        this.bildirimStatus = React.createRef();
        this.toplanmaStatus = React.createRef();
        this.municipalities = React.createRef()
        this.state = {
            showWasteReports: false,
            dataCame: false,
            ministries: [],
            nelerAtilabilirOption: null,
            bildirimStatusOption: null,
            atikToplanmaDurumuOption: null,
            municipalitiesOption: null,
            nelerAtilabilir: [],
            bildirimStatus: [],
            atikToplanmaDurumu: [],
            selectedSGun: { value: 0, label: "Tümü" },
            selectedSYil: { value: 0, label: "Tümü" },
            selectedSAy: { value: 0, label: "Tümü" },
            selectedEGun: { value: 0, label: "Tümü" },
            selectedEYil: { value: 0, label: "Tümü" },
            selectedEAy: { value: 0, label: "Tümü" },
            registeredMinistry: { value: "Tümü", label: "Tümü" },
            aylarText: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
            wasteReports: [],
            municipalityOptions: [],
            registeredMinistryDistrict: "",
            isSelf: { value: false, label: "Belediye'ye bildirimler" }
        }
    }


    getMunicipalities() {
        const ministryRequest = {
            query: `query {
                getMinistry{
                    _id
                    district
                }
            }`
        }

        call(ministryRequest).then(async resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }
            let municipalityOptions = [{ value: "Tümü", label: "Tümü" }]
            for (let i = 0; i < resData.data.getMinistry.length; i++) {
                let object = { value: resData.data.getMinistry[i]._id, label: resData.data.getMinistry[i].district }
                municipalityOptions.push(object)
            }

            await this.setState({ ministries: resData.data.getMinistry, dataCame: true, municipalityOptions: municipalityOptions })
        }).catch(err => { console.log(err) })

    }

    async componentWillMount() {
        await this.getMunicipalities()
    }

    async setTimeState(varName, selectedOption) {
        await this.setState({ [varName]: selectedOption })
    }

    handleChangeNelerAtilabilir = async selectedOption => {
        let newArr = []
        await this.setState({ nelerAtilabilirOption: selectedOption })
        if (selectedOption === null) {
            await this.setState({ nelerAtilabilir: [] })
        } else {
            for (var i = 0; i < selectedOption.length; i++) {
                newArr.push(selectedOption[i].value)
            }
            await this.setState({ nelerAtilabilir: newArr })
        }
    };

    handleChangeToplanmaDurumu = async selectedOption => {
        let newArr = []
        await this.setState({ atikToplanmaDurumuOption: selectedOption })
        if (selectedOption === null) {
            await this.setState({ atikToplanmaDurumu: [] })
        } else {
            for (var i = 0; i < selectedOption.length; i++) {
                newArr.push(selectedOption[i].value)
            }
            await this.setState({ atikToplanmaDurumu: newArr })
        }

    };

    handleChangeReportDurumu = async selectedOption => {
        let newArr = []
        await this.setState({ bildirimStatusOption: selectedOption })
        if (selectedOption === null) {
            await this.setState({ bildirimStatus: [] })
        } else {
            for (var i = 0; i < selectedOption.length; i++) {
                newArr.push(selectedOption[i].value)
            }
            await this.setState({ bildirimStatus: newArr })
        }

    };

    async setFilter() {
        const advancedReportRequest = {
            query: `query {
                advancedReportQuery(
                    types:[${this.state.nelerAtilabilir}], 
                    wasteStatus:"${this.state.bildirimStatus}", 
                    wasteExplanation: ["${this.state.atikToplanmaDurumu}"], 
                    registeredMinistry: "${this.state.registeredMinistry.value}", 
                    sMonth:${this.state.selectedSAy.value}, 
                    sDay:${this.state.selectedSGun.value}, 
                    sYear:${this.state.selectedSYil.value}, 
                    eMonth:${this.state.selectedEAy.value}, 
                    eDay:${this.state.selectedEGun.value}, 
                    eYear:${this.state.selectedEYil.value},
                    isSelf:${this.state.isSelf.value})
                    {
                        _id
                        wasteType
                        images
                        latitude
                        longitude
                        createdAt
                        waste_status
                        statusExplanation
                        creator{email}
                    }
            }`
        }
        await this.setState({ dataCame: false })
        call(advancedReportRequest).then(async resData => {
            if (resData.errors) {
                console.log("unauthenticated")
                createDataTable()
            }
            await this.setState({ wasteReports: resData.data.advancedReportQuery, dataCame: true, showWasteReports: true })
            createDataTable()
        }).catch(async err => {
            console.log(advancedReportRequest); await this.setState({ dataCame: true }); createDataTable()
        })

    }

    getFilterOptions() {
        return <><div class="row page-titles">


            <div class="col-md-5">
                <label>Belediye Filtrele</label>
                <Select value={this.state.registeredMinistry} onChange={(selectedOption) => { this.setState({ registeredMinistry: selectedOption }) }} isMulti={false} ref={this.municipalities} options={this.state.municipalityOptions} placeholder="Tümü" />

                <label>Atık Tiplerini Seçin</label>
                <Select value={this.state.nelerAtilabilirOption} onChange={this.handleChangeNelerAtilabilir} isMulti={true} ref={this.nelerAtilabilir} options={nelerAtilirOptions} placeholder="Tümü" />
                {this.state.isSelf.value === false && <label>Atık Durumunu Seçin</label>}
                {this.state.isSelf.value === false && <Select value={this.state.bildirimStatusOption} onChange={this.handleChangeReportDurumu} isMulti={true} ref={this.bildirimStatus} options={bildirimStatusOptions} placeholder="Bildirim Durumu" />}
                {this.state.isSelf.value === false && <label>Atık Toplanma Durumunu Seçin</label>}
                {this.state.isSelf.value === false && <Select value={this.state.atikToplanmaDurumuOption} onChange={this.handleChangeToplanmaDurumu} isMulti={true} ref={this.toplanmaStatus} options={atikToplanmaDurumuOptions} placeholder="Bildirim Durumu" />}
                <label>Belediyeye/Kendi</label>
                <Select value={this.state.isSelf} onChange={(selectedOption) => { this.setState({ isSelf: selectedOption }) }} isMulti={false} options={isSelfOption} placeholder="Belediye'ye bildirimler" />
            </div>

            <div class="col-md-2 align-self-center">
                <p style={{ fontWeight: "bold", fontSize: 16 }}>Başlangıç Tarihi</p>
                <hr></hr>
                <DateOption
                    gun={"selectedSGun"}
                    ay={"selectedSAy"}
                    yil={"selectedSYil"}
                    selectedGun={this.state.selectedSGun}
                    selectedAy={this.state.selectedSAy}
                    selectedYil={this.state.selectedSYil}
                    setFunction={this.setTimeState.bind(this)}
                />
            </div>
            <div class="col-md-2 align-self-center">
                <p style={{ fontWeight: "bold", fontSize: 16 }}>Bitiş Tarihi</p>
                <hr></hr>
                <DateOption
                    gun={"selectedEGun"}
                    ay={"selectedEAy"}
                    yil={"selectedEYil"}
                    selectedGun={this.state.selectedEGun}
                    selectedAy={this.state.selectedEAy}
                    selectedYil={this.state.selectedEYil}
                    setFunction={this.setTimeState.bind(this)}
                />
            </div>
            <button class="btn btn-primary" type="button" style={{ marginTop: "4%" }} onClick={async () => { this.setFilter() }}>Filtreyi Uygula</button>
        </div></>
    }

    render() {
        if (!this.state.dataCame) {
            return (
                <div class="page-wrapper">
                    <div class="container-fluid">
                        <h1>Bekleyin...</h1>
                    </div>
                </div>
            )
        }
        else if (!this.state.showWasteReports) {
            return (

                <div class="page-wrapper">
                    <div class="container-fluid">
                      
                        {this.getFilterOptions()}
                    </div>
                </div>
            )
        }
        return (

            <div class="page-wrapper">
                <div class="container-fluid">
                    {this.getFilterOptions()}
                    <TableComponent
                        tableHeadersArray={
                            [
                                "Kullanıcı",
                                "Durum",
                                "Durum Açıklaması",
                                "Atık Tipleri",
                                "Bildirim Tarihi",
                                "Görüntüle",
                            ]
                        }
                        tableContentArray={
                            this.state.wasteReports.map(rep => {
                                return [
                                    rep.creator.email,
                                    getWasteStatus(rep.waste_status, rep.statusExplanation),
                                    rep.statusExplanation !== null ? notificationExplanation(rep.statusExplanation) : "Girilmemiş",
                                    ShowKonteynerNameAccordingToType(rep.wasteType),
                                    <><span style={{ display: "none" }}>{dateConvertionForSort(new Date(Number(rep.createdAt)))}</span>{dateConvertion(new Date(Number(rep.createdAt)))}</>,
                                    <><span class="text-muted"><i class="fa fa-clock-o"></i> <Link target="_blank" to={{ pathname: `/wasteReportDetail?_id=${rep._id}`, aboutProps: { report: rep } }}>Görüntüle</Link></span></>,
                                ]
                            })
                        }
                        images={
                            this.state.wasteReports.map(rep => {
                                if(rep.images instanceof Array){
                                    if(rep.images.length !== 0){
                                        if(rep.images[0] !== undefined)
                                            return "https://api.kollektapp.org/" + rep.images[0].slice(10)
                                    }
                                }
                            })
                        }
                        pageHeader={"Gelişmiş Atık Bildirimleri"}
                    />
                </div>
            </div>
        )
    }
}

export default AdvancedReportQuery