import React,{Component, } from 'react'

import {call} from '../services/api'

import Select from 'react-select'

const konteynerTipOptions = [
    { value: 1, label: 'Belediye' },
    { value: 2, label: 'Cam Kumbarası' },
    { value: 3, label: 'Pet Şişe Kafesi' },
    { value: 4, label: 'Karışık Ambalaj' },
    { value: 5, label: 'Tekli Çoklu Özel Kutu' },
    { value: 6, label: 'Atık Getirme Merkezi' },
]

const nelerAtilirOptions = [
    { value: 1, label: 'Pet/Plastik' },
    { value: 2, label: 'Cam' },
    { value: 3, label: 'Kağıt/Karton' },
    { value: 4, label: 'Teneke Kutu/Metal' },
    { value: 5, label: 'Mutfak/Yemek Artıkları' },
    { value: 6, label: 'İnşaat/Moloz' },
    { value: 7, label: 'Bebek Bezi' },
    { value: 8, label: 'Park Bahçe Atıkları' },
    { value: 9, label: 'Araba Lastiği' },
    { value: 10, label: 'Eski Ev Eşyaları' },
    { value: 11, label: 'Cam Kırıkları' },
    { value: 12, label: 'Tıbbi Atık' },
    { value: 13, label: 'Elektronik Atık' },
    { value: 14, label: 'Bitkisel Atık Yağ' },
    { value: 15, label: 'tekstil Atıkları' },
    { value: 16, label: 'Meyve Sebze Artıkları' },
    { value: 17, label: 'Pil' },
    { value: 18, label: 'Atık İlaç' },
    { value: 19, label: 'Akü/Elektrikli Araç Bataryası'},
    { value: 20, label: 'Atık Motor Yağı' },
]

const bosaltilmaOptions = [
    { value: 1, label: 'Daha Sık' },
    { value: 2, label: 'Her gün' },
    { value: 3, label: '2 günde bir' },
    { value: 4, label: '3 günde bir' },
    { value: 5, label: 'Haftada bir' },
    { value: 6, label: 'Daha az' },
]

const saglamlikOptions = [
    { value: 1, label: 'Sağlam' },
    { value: 2, label: 'Bakım Gerekli' },
    { value: 3, label: 'Değişim Gerekli' },
]


class AddKonteyner extends Component {
    constructor(props){
        super(props)
        this.latitude  = React.createRef();
        this.longitude = React.createRef();
        this.konteynerAdet = React.createRef();
        this.mahalleAdi = React.createRef();
        this.sokakAdi = React.createRef();
        this.konteynerType = React.createRef();
        this.nelerAtilabilir = React.createRef();

        this.state = {
            nelerAtilabilir: null,
            nelerAtilabilirOption: null,
            konteynerType: null,
            durumBilgisi: null,
            saglamlik: null,
            addingSuccess:"",
            selectedMinistryId: "Seç",
            ministries:[],
        }
    }

    async componentWillMount(){
        const ministryRequest = {
            query: `query {
                getMinistry{
                    _id
                    district
                }
            }`
        }

        call(ministryRequest).then(resData=>{
            if(resData.errors) 
            {
                console.log("unauthenticated")
            }
            console.log(resData)
           
            
            this.setState({ministries: resData.data.getMinistry,  dataCame:true})
            
        }).catch(err=>{console.log(err)})
    }

    handleChangeNelerAtilabilir = async selectedOption => {
        let newArr = []
        await this.setState({nelerAtilabilirOption: selectedOption})
        if(selectedOption === null){
            await this.setState({nelerAtilabilir: null})
        }else{
            for(var i = 0; i < selectedOption.length; i++){
                newArr.push(selectedOption[i].value)
            }
            await this.setState({nelerAtilabilir: newArr})
        }
        console.log(`Option selected:`, this.state.nelerAtilabilir);

    };

    handleChangeKonteynerType = async selectedOption => {
        await this.setState({konteynerType: selectedOption.value})
        if(selectedOption.value === 1){
            this.setState({nelerAtilabilirOption: [
                { value: 5, label: 'Mutfak/Yemek Artıkları' },
                { value: 7, label: 'Bebek Bezi' },
            ]})
        }else if(selectedOption.value === 2){
            this.setState({nelerAtilabilirOption: [
                { value: 2, label: 'Cam' },
            ]})
        }else if(selectedOption.value === 3){
            this.setState({nelerAtilabilirOption: [
                { value: 1, label: 'Pet/Plastik' },
            ]})
        }else if(selectedOption.value === 4){
            this.setState({nelerAtilabilirOption: [
                { value: 1, label: 'Pet/Plastik' },
                { value: 2, label: 'Cam' },
                { value: 3, label: 'Kağıt/Karton' },
                { value: 4, label: 'Teneke Kutu/Metal' },
            ]})
        }else if(selectedOption.value === 5){
            this.setState({nelerAtilabilirOption: null})
        }else if(selectedOption.value === 6){
            this.setState({nelerAtilabilirOption: null})
        }
        console.log(`Option selected:`, this.state.nelerAtilabilirOption);
    };

    handleChangeDurumBilgisi = async selectedOption => {
        await this.setState({durumBilgisi: selectedOption.value})
        console.log(`Option selected:`, selectedOption);
    };

    handleChangeBosaltilma = async selectedOption => {
        await this.setState({bosaltilma: selectedOption.value})
        console.log(`Option selected:`, selectedOption);
    };

    addKonteyner = async (event) => {
        event.preventDefault()
        const konteynerType = this.konteynerType.current.value
        const latitude = this.latitude.current.value
        const longitude = this.longitude.current.value
        const konteynerAdet = this.konteynerAdet.current.value
        const mahalleAdi = this.mahalleAdi.current.value
        const sokakAdi = this.sokakAdi.current.value
        const selectedMinistry = this.state.selectedMinistryId

        if(selectedMinistry === "Seç"){
            await this.setState({addingSuccess:"Lütfen belediyeyi seçin"})
            return
        }
    
        let nelerAtilabilirOp = []
        if(this.state.nelerAtilabilirOption !== null){
            for(let i = 0; i < this.state.nelerAtilabilirOption.length; i++){
                nelerAtilabilirOp.push(this.state.nelerAtilabilirOption[i].value)
            }
        }
        

   
        const konteynerRequestBody = {
            query: `mutation{
                createKonteynerWithoutImage(konteynerType:${this.state.konteynerType}, type:[${nelerAtilabilirOp}], konteynerNumber:${konteynerAdet}, mahalle:"${mahalleAdi}", sokak:"${sokakAdi}", latitude:${latitude}, longitude:${longitude}, ministryId:"${selectedMinistry}"){
                _id
                }
            }`
        }

        call(konteynerRequestBody)
        .then(async res => {
            console.log(res.data.createKonteynerWithoutImage)
            if(res.errors){
                await this.setState({addingSuccess:"Konteyner eklenemedi"})
            }else{
                await this.setState({addingSuccess:"Konteyner başarıyla eklendi"})
            }
        })
        .catch(async err => {
            await this.setState({addingSuccess:"Konteyner eklenemedi"})
        })
    }

    

    render(){
        return(
            <div class="page-wrapper">
                <div class="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h1>Konteyner Ekle</h1>
                                    <form className="mt-4"> 
                                        <div className="form-group">
                                            <label>Belediye Seç</label><br></br>
                                            <select class="form-control-lg " onChange={async (e) => {await this.setState({ selectedMinistryId: e.target.value })}}>
                                            <option value="Seç">Seç</option>
                                                {this.state.ministries.map(ministry => {
                                                    return(
                                                        <option value={ministry._id}>{ministry.district}</option>
                                                    )
                                                })}                                          
                                            </select>                                        
                                        </div>
                                        <div className="form-group">
                                            <label>Latitude/Enlem</label>
                                            <input type="number" className="form-control" ref={this.latitude} placeholder="Konteyner enlem değerini giriniz" required/>
                                        </div>
                                        <div className="form-group">
                                            <label>Longitude/Boylam</label>
                                            <input type="number" className="form-control" ref={this.longitude} placeholder="Konteyner boylam değerini giriniz" required/>
                                        </div>
                                        <div className="form-group">
                                            <label>Konteyner Adedi</label>
                                            <input type="number" className="form-control" ref={this.konteynerAdet} placeholder="Konteyner adedini girin" required/>
                                        </div>
                                        <label>Konteyner Tipi</label>
                                        <Select onChange={this.handleChangeKonteynerType} ref={this.konteynerType} options={konteynerTipOptions} placeholder="Konteyner Tipi"/>
                                        <label>Neler Atılabilir</label>
                                        <Select value={this.state.nelerAtilabilirOption} onChange={this.handleChangeNelerAtilabilir} isMulti={true} ref={this.nelerAtilabilir} options={nelerAtilirOptions} placeholder="Neler Atılabilir"/>
                                  
                                        <div className="form-group">
                                            <label>Mahalle Adı</label>
                                            <input type="text" className="form-control" ref={this.mahalleAdi} placeholder="Mahalle adını giriniz" required/>
                                        </div>
                                        <div className="form-group">
                                            <label>Sokak adı</label>
                                            <input type="text" className="form-control" ref={this.sokakAdi} placeholder="Sokak adını giriniz" required/>
                                        </div>
                                        <h3 style={this.state.addingSuccess === "Konteyner eklenemedi" ? {color:"red"} : {color:"green"}}>{this.state.addingSuccess}</h3>
                                        <button type="submit" onClick={this.addKonteyner} className="btn btn-primary">Konteyner Oluştur</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddKonteyner