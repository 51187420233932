import {createStore,compose,applyMiddleware,combineReducers} from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from './reducers/auth'
import errorReducer from './reducers/error'
const DEFAULT_STATE = {
    error: {message:null},
}

const authConfig = {
    timeout: 0,
    key: 'persistConfig',
    storage: storage,
    blackBox: ['errorConfig'] 
}
const errorConfig = {
    timeout: 0,
    key: 'persistConfig',
    storage: storage,
    blackBox: ['authReducer']
};

const rootReducer = combineReducers ({
    auth: persistReducer(authConfig, authReducer),
    error: persistReducer(errorConfig, errorReducer),
});
/*export const store = createStore(
    rootReducer,
    DEFAULT_STATE,
    compose(
        applyMiddleware(thunk),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
)*/

export const store = createStore(rootReducer,DEFAULT_STATE,
    compose(
        applyMiddleware(thunk),
    ))
export const persistor = persistStore(store)