import React, { Component } from 'react'
import AuthContext from '../context/auth-context'
import { NavLink, Link } from 'react-router-dom'
import { dateConvertion } from './helpers/dateHelpers'
import { call } from '../services/api'
import { Redirect, Route } from 'react-router-dom'

class MainPage extends Component {

    state = {
        wasteReports: [],
        activeWasteReportCount: 0,
        allWasteReportCount: 0,
        konteynerNumber: 0,
        kemerArea: "",
    }

    async componentWillMount() {
        console.log(this.props.auth)
        //comment
        if (window.location.hash === "#login") {
            window.location.hash = ""
            window.location.reload()
        }
        const requestBody = {
            query: `
                query{
                    wasteReportsAll
                    {
                        _id
                        images
                        privacy
                        wasteType
                        latitude
                        longitude
                        createdAt
                        isSelf
                        waste_status
                        createdAt
                        creator {email}
                    }
                }
            `
        }


        call(requestBody).then(resData => {
            if (resData.errors) {
                console.log(resData.errors)
            }
            else {
                let wasteRepr = []
                resData.data.wasteReportsAll.map(rep => {
                    if (rep.isSelf === false) {
                        wasteRepr.push(rep)
                    }
                })
                this.setState({ wasteReports: wasteRepr })
            }
        }).catch(err => { console.log(err) })



        const requestBody3 = {
            query: `
                query{
                    getWasteReportNumber
                    {
                        activeCount
                        allCount
                    }
                }
            `
        }
        call(requestBody3).then(resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }

            this.setState({
                activeWasteReportCount: resData.data.getWasteReportNumber.activeCount,
                allWasteReportCount: resData.data.getWasteReportNumber.allCount
            })

        }).catch(err => { console.log(err) })

        const requestBody4 = {
            query: `
                query{
                    getKonteynerNumber
                    {
                        konteynerNumber
                    }
                }
            `
        }
        call(requestBody4).then(resData => {
            if (resData.errors) {
                console.log("unauthenticated")
            }

            this.setState({
                konteynerNumber: resData.data.getKonteynerNumber.konteynerNumber,
            })

        }).catch(err => { console.log(err) })
    }

    showStatus(stat) {
        if (stat === "bekliyor") {
            return "beklemede"
        }
    }
    

    render() {

        return (

            <div class="page-wrapper">

                <div class="container-fluid">
                    <div class="row page-titles">
                        <div class="col-md-5 align-self-center">
                            <h4 class="text-themecolor">Anasayfa</h4>
                        </div>
                        <div class="col-md-7 align-self-center text-right">
                            <div class="d-flex justify-content-end align-items-center">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                                    <li class="breadcrumb-item active">Anasayfa</li>
                                </ol>
                            </div>
                        </div>
                    </div>



                    <div class="row">

                        <div class="col-lg-4 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row p-t-10 p-b-10">

                                        <div class="col p-r-0">
                                            <h1 class="font-light">{this.state.activeWasteReportCount}</h1>
                                            <h6 class="text-muted">Aktif Bildirim</h6></div>

                                        <div class="col text-right align-self-center">
                                            <div data-label="100%" class="css-bar m-b-0 css-bar-primary css-bar-100"><i class="mdi mdi mdi-animation"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row p-t-10 p-b-10">

                                        <div class="col p-r-0">
                                            <h1 class="font-light">{this.state.allWasteReportCount}</h1>
                                            <h6 class="text-muted">Toplam Bildirim</h6></div>

                                        <div class="col text-right align-self-center">
                                            <div data-label="100%" class="css-bar m-b-0 css-bar-danger css-bar-20"><i class="mdi mdi-briefcase-check"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row p-t-10 p-b-10">

                                        <div class="col p-r-0">
                                            <h1 class="font-light">{this.state.konteynerNumber}</h1>
                                            <h6 class="text-muted">Kayıtlı Konteynır</h6></div>

                                        <div class="col text-right align-self-center">
                                            <div data-label="40%" class="css-bar m-b-0 css-bar-warning css-bar-40"><i class="mdi mdi-city"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex">
                                        <div>
                                            <h5 class="card-title">Son Bildirimler</h5>
                                        </div>

                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <table class="table table-hover">

                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th>Bildirim Numarası</th>
                                                <th>Kullanıcı</th>

                                                <th>Durum</th>
                                                <th>Bildirim Tarihi</th>
                                            </tr>
                                        </thead>
                                        {this.state.wasteReports.map(report =>
                                            report.isSelf === false &&
                                            <tbody>
                                                <tr>
                                                    <td class="text-center"><i class="mdi mdi-receipt"></i></td>
                                                    <td class="txt-oflo">{report._id}</td>
                                                    {report.creator !== null &&
                                                        <td class="txt-oflo">{report.creator.email}</td>}
                                                    {report.waste_status === "bekliyor" &&
                                                        <td><span class="badge badge-success badge-pill" style={{ backgroundColor: "#ffbf3e" }}>{report.waste_status}</span> </td>
                                                    }
                                                    {report.waste_status === "Toplandı" &&
                                                        <td><span class="badge badge-success badge-pill" style={{ backgroundColor: "#7abe45" }}>{report.waste_status}</span> </td>
                                                    }
                                                    {report.waste_status === "İptal Edildi" &&
                                                        <td><span class="badge badge-success badge-pill" style={{ backgroundColor: "#fc4b6c" }}>{report.waste_status}</span> </td>
                                                    }
                                                    {report.waste_status === "Çözüm Aranıyor" &&
                                                        <td><span class="badge badge-success badge-pill" style={{ backgroundColor: "#c47886" }}>{report.waste_status}</span> </td>
                                                    }

                                                    <td><span class="text-muted"><i class="fa fa-clock-o"></i> {dateConvertion(new Date(Number(report.createdAt)))}</span></td>
                                                    <td><span class="text-muted"><i class="fa fa-clock-o"></i> <Link target="_blank" to={{ pathname: `/wasteReportDetail?_id=${report._id}`, aboutProps: { report: report } }}>Görüntüle</Link></span></td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}

export default MainPage