import React from 'react'
import { getWasteStatus } from '../tableScreen/componentHelpers/generalHelpers'
import { dateConvertion, dateConvertionForSort } from '../../helpers/dateHelpers'
import { Link } from 'react-router-dom'
import { ShowKonteynerNameAccordingToType } from '../../helpers/markerHelpers'

export const municipalityReportsHeaders = () => {

    let tableHeadersArray =
        [
            "Bildirim Numarası",
            "Kullanıcı",
            "Durum",
            "Belediye",
            "Bildirim Tarihi",
            "Görüntüle",
            "Sil"
        ]

    return tableHeadersArray
}

export const municipalityReportsContent = (rep) => {

    let tableContentArray =
        [
            rep._id,
            rep.creator !== null ? rep.creator.email : "kullanıcı bilgisi girilmemiş",
            getWasteStatus(rep.waste_status, rep.statusExplanation),
            rep.registeredMinistry !== null ? rep.registeredMinistry.district : "Belediye bilgisi yok",
            <><span style={{ display: "none" }}>{dateConvertionForSort(new Date(Number(rep.createdAt)))}</span>{dateConvertion(new Date(Number(rep.createdAt)))}</>,
            <><span class="text-muted"><i class="fa fa-clock-o"></i> <Link target="_blank" to={{ pathname: `/wasteReportDetail?_id=${rep._id}`, aboutProps: { report: rep } }}>Görüntüle</Link></span></>,
        ]

    return tableContentArray
}

export const isSelfReportHeaders = () => {
    let tableHeadersArray =
        [
            "Bildirim Numarası",
            "Kullanıcı",
            "Belediye",
            "Enlem",
            "Boylam",
            "Atık Tipleri",
            "Bildirim Tarihi",
            "Görüntüle",
            "Sil"
        ]
    return tableHeadersArray
}

export const isSelfReportsContent = (rep) => {
    let tableContentArray =
        [
            rep._id,
            rep.creator !== null ? rep.creator.email : "kullanıcı bilgisi girilmemiş",
            rep.registeredMinistry !== null ? rep.registeredMinistry.district : "Belediye bilgisi yok",
            rep.latitude,
            rep.longitude,
            ShowKonteynerNameAccordingToType(rep.wasteType),
            <><span style={{ display: "none" }}>{dateConvertionForSort(new Date(Number(rep.createdAt)))}</span>{dateConvertion(new Date(Number(rep.createdAt)))}</>,
            <><span class="text-muted"><i class="fa fa-clock-o"></i> <Link target="_blank" to={{ pathname: `/wasteReportDetail?_id=${rep._id}`, aboutProps: { report: rep } }}>Görüntüle</Link></span></>,
        ]    
    return tableContentArray                                                  
}

