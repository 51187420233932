import React, { Component } from 'react'
export default class PageWrapper extends Component{

    constructor(props){
        super(props)
    }

    render() {
        return (

            <div class="page-wrapper">
                <div class="container-fluid">
                    {this.props.children}
                </div>
            </div>

        )
    }
} 