import React,{Component} from 'react'

import {call} from '../../services/api'


class AddCommunicationInfo extends Component{

    constructor(props)
    {
        super(props);
        this.kurumName = React.createRef();
        this.email = React.createRef();
        this.phone = React.createRef();
        this.address = React.createRef();
        this.latitude = React.createRef();
        this.longitude = React.createRef();

        this.state = {
          resultMessage:""
        }
    }


 createKurum = (event)=>{
    event.preventDefault()
    const kurumName = this.kurumName.current.value
    const email = this.email.current.value
    const phone = this.phone.current.value
    const address = this.address.current.value
    const latitude = this.latitude.current.value
    const longitude = this.longitude.current.value

    const requestBody = {
        query: `
            mutation{
                createCommunicationInfo(kurumName:"${kurumName}",email:"${email}",phone:"${phone}",address:"${address}",latitude:${latitude},longitude:${longitude})
                {
                  _id
                }
            }
        `}
    

    call(requestBody).then(resData=>{
            if(resData.errors)
            {
              this.setState({resultMessage:"Kurum eklenemedi"})
            }else{
              this.setState({resultMessage:"Kurum başarıyla eklendi"})
            }
        }).catch(err=>{console.log(err); this.setState({resultMessage:"Kurum eklenemedi"})})
  }

    render(){
        return(
          <div className="page-wrapper">  
            <div className="container-fluid">          
              <div className="row page-titles">
                <div className="col-md-5 align-self-center">
                  <h4 className="text-themecolor">Kurum Oluşturma</h4>
                </div>                
              </div>            
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Kurum İletişim Bilgilerini Girin</h4>
                      
                      <form className="mt-4">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Kurum Adı</label>
                          <input type="text" className="form-control" ref={this.kurumName} placeholder="Kurum adını yazın" required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Kurum Email Adresi</label>
                          <input type="text" className="form-control" ref={this.email} placeholder="Kurum email adresini girin" required/>
                        </div>
                        
                        <div className="form-group">
                            <label>Telefon Numarasını</label>
                            <input type="text" class="form-control" ref={this.phone} placeholder="Telefon numarasını girin" required/>                
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Kurum Adresi</label>
                          <input type="text" className="form-control" ref={this.address} placeholder="Kurum adresini girin" required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Enlem</label>
                          <input type="number" className="form-control" ref={this.latitude} placeholder="Enlem derecesini girin" required/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Boylam</label>
                          <input type="number" className="form-control"ref={this.longitude} placeholder="Boylam derecesini girin" required/>
                        </div>
                        <p style={this.state.resultMessage === "Kurum eklenemedi" ? {color:"red"} : {color:"green"}}>{this.state.resultMessage}</p>
                        <button type="submit" onClick={this.createKurum} className="btn btn-primary">Kurum Bilgisi Oluştur</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export default AddCommunicationInfo