import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import {createUploadLink} from 'apollo-upload-client'
import {ApolloClient} from 'apollo-client'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {ApolloProvider} from 'react-apollo'

import {url} from './services/api'

const link = createUploadLink({uri:url})

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
})

ReactDOM.render(
    <ApolloProvider client={client}> <App /> </ApolloProvider>
    ,document.getElementById('root'));
