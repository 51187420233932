import React, { Component, } from 'react'
import { OutTable, ExcelRenderer } from 'react-excel-renderer';


class MunicipalityEnterExcel extends Component {
    constructor() {
        super()
        this.state = {
            polygonArray: [],
            polygonObject: {
                id: "",
                latLong: []
            },
            polygonObjectArray: [

            ],
            allObjects: [

            ]
        }
    }


    fileHandler = (event) => {
        let fileObj = event.target.files[0];
        let fixedArray = []
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, async (err, resp) => {


            if (err) {
                console.log(err);
            }
            else {
                for (let i = 1; i < resp.rows.length; i++) {
                    let arrayLatLong = []
                    //Because data came as long-lat and we are working as lat-long for start as reverse
                    for (let k = resp.rows[i].length - 1; k > 0; k--) {
                        arrayLatLong.push(resp.rows[i][k])
                    }
                    fixedArray.push(arrayLatLong)
                }

                let polygonObject = { id: fileObj.name.substring(0, fileObj.name.length - 4), latLong: fixedArray }
                let allObjects = this.state.polygonObjectArray
                allObjects.push(polygonObject)
                console.log(allObjects)
                await this.setState({
                    polygonObjectArray: allObjects,
                    allObjects
                });

            }
        });
    }

    addMunicipality() {

    }

    render() {

        return (
            <div class="page-wrapper">
                <div class="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">

                                    <form className="mt-4">
                                        <div className="form-group">


                                            <input type="file" onChange={this.fileHandler.bind(this)} style={{ "padding": "10px" }} required />
                                        </div>

                                    </form>
                                    {this.state.allObjects.length !== 0 &&
                                        this.state.allObjects.map(obj => {
                                            return (
                                                <React.Fragment>
                                                    <br></br>
                                                    <p style={{ display: "inline" }}>{"{"}</p><br></br>
                                                    <p style={{ marginLeft: 40, display: "inline" }}>id: "{obj.id}",</p><br></br>
                                                    <p style={{ display: "inline", marginLeft: 40 }}>latLong:[</p><br></br>
                                                    {obj.latLong.map(latLong => {
                                                        let string = "[" + latLong[0] + "," + latLong[1] + "],"
                                                        return (
                                                            <React.Fragment>
                                                                <p style={{ display: "inline", marginLeft: 80 }}>{string}</p>
                                                                <br></br>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    <p style={{ display: "inline", marginLeft: 40 }}>]</p><br></br>
                                                    <p style={{ display: "inline" }}>{"}"},</p>
                                                </React.Fragment>
                                            )
                                        })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default MunicipalityEnterExcel