import React, { Component } from 'react'

class PageHeader extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div class="row page-titles">
                <div class="col-md-5 align-self-center">
                    <h4 class="text-themecolor">{this.props.pageHeader}</h4>
                </div>
                <div class="col-md-7 align-self-center text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Temiz Akdeniz</a></li>
                            <li class="breadcrumb-item active">{this.props.pageHeader}</li>
                        </ol>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageHeader