import React, { Component } from 'react'
import { connect } from 'react-redux';
import WasteReportTableComponent from "../components/wasteReportComponent"
import { getDkmActiveWasteReports } from './apiCalls.js/apiEndpoints'

class ActiveWasteReports extends Component {
    render() {
        return (
            <WasteReportTableComponent
                reportApiCall={getDkmActiveWasteReports}
                pageHeader="Aktif Atık Bildirimleri"
                contentType="municipality"
            />
        )
    }
}

export default connect((store) => ({ auth: store.auth }))(ActiveWasteReports)
