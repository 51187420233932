import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getDkmIsSelfReports } from './apiCalls.js/apiEndpoints'
import WasteReportTableComponent from "../components/wasteReportComponent"

class IsSelfWasteReports extends Component {
    render() {
        return (
            <WasteReportTableComponent
                reportApiCall={getDkmIsSelfReports}
                pageHeader="Kendim Atarım Atık Bildirimleri"
                contentType="isSelf"
            />
        )
    }
}

export default connect((store) => ({ auth: store.auth }))(IsSelfWasteReports)
